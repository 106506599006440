const apiConfig = {
  login: {
    name: "login",
    urlEndpoint: "login/otp?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: false,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 3,
  },
  otp: {
    name: "otp",
    urlEndpoint: "login/otp",
    httpMethod: "POST",
    isAuthenticated: false,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 3,
  },
  logout: {
    name: "logout",
    urlEndpoint: "auth/logout",
    httpMethod: "POST",
    isAuthenticated: true,
    hasQueryProps: false,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  summary: {
    name: "summary",
    urlEndpoint: "team/summary",
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 2,
  },
  employees: {
    name: "employees",
    urlEndpoint: "team/employees?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 5,
  },
  status: {
    name: "status",
    urlEndpoint: "employee-request/employee-status-update",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 7,
  },
  export: {
    name: "export",
    urlEndpoint: "team/employees/export",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 4,
  },
  exportEmployeeRequests: {
    name: "exportEmployeeRequests",
    urlEndpoint: "employee-request/export?",
    httpMethod: "POST",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 3,
  },
  email: {
    name: "email",
    urlEndpoint: "team/email",
    httpMethd: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  employeeGroup: {
    name: "employeeGroup",
    urlEndpoint: "team/employeeGroup",
    httpMethd: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 2,
  },
  progressExport: {
    name: "progressExport",
    urlEndpoint: "employee-request/export?",
    httpMethod: "POST",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 3,
  },
  singleCreate: {
    name: "singleCreate",
    urlEndpoint: "employee-request/single-create",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 7,
  },
  inProgress: {
    name: "inProgress",
    urlEndpoint: "employee-request?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 3,
  },
  paymentCycles: {
    name: "paymentCycles",
    urlEndpoint: "payment-cycles?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  getTransactions: {
    name: "getTransactions",
    urlEndpoint: "transactions?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 2,
  },
  exportTransactionList: {
    name: "exportTransactionList",
    urlEndpoint: "transactions/export?",
    httpMethod: "POST",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 2,
  },
  getEmployeeData: {
    name: "getEmployeeData",
    urlEndpoint: "team/employees/{employeeId}",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 3,
  },
  getEmployeeRequestById: {
    name: "getEmployeeRequestById",
    urlEndpoint: "employee-request/{employeeId}",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 2,
  },
  singleUpdate: {
    name: "singleUpdate",
    urlEndpoint: "employee-request/single-update",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 7,
  },
  getBanksList: {
    name: "getBanksList",
    urlEndpoint: "team/bank/list",
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  bulkCreate: {
    name: "bulkCreate",
    urlEndpoint: "employee-request/file-upload/xlsx",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 8,
  },
  getEmployerConfig: {
    name: "getEmployerConfig",
    urlEndpoint: "employer/config",
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 3,
  },
  sdSchedulingFileUpload: {
    name: "sdSchedulingFileUpload",
    urlEndpoint: "salary-disbursement-request/file-upload",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 2,
  },
  getSdRequestList: {
    name: "getSdRequestList",
    urlEndpoint: "salary-disbursement-request/request/{requestId}/records?",
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: true,
  },
  submitScheduling: {
    name: "submitScheduling",
    urlEndpoint: "salary-disbursement-request/raise-requests",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 2,
  },
  wipeRecordsScheduled: {
    name: "wipeRecordsScheduled",
    urlEndpoint: "salary-disbursement-request/delete/records",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  getSdRequests: {
    name: "getSdRequests",
    urlEndpoint: "salary-disbursement-request/requests?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 2,
  },
  getSdRequestDetails: {
    name: "getSdRequestDetails",
    urlEndpoint: "salary-disbursement-request/request/{requestId}/records?",
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: true,
    version: 2,
  },
  downloadAllSDRequests: {
    name: "downloadAllSDRequests",
    urlEndpoint: "salary-disbursement-request/requests/export",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 2,
  },
  downloadSdRequest: {
    name: "downloadSdRequest",
    urlEndpoint: "salary-disbursement-request/requests/{requestId}/export?",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: true,
  },
  getCategories: {
    name: "getCategories",
    urlEndpoint: "employee-request/suspension-categories?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 3,
  },
  getGroupsAndPayDates: {
    name: "getGroupsAndPayDates",
    urlEndpoint: "employee-request/groups-paydates",
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 2,
  },
  fetchGroupPayDates: {
    name: "fetchGroupPayDates",
    urlEndpoint: "group-paydate?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 2,
  },
  fetchMeApiData: {
    name: "fetchMeApiData",
    urlEndpoint: "employer/me",
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 2,
  },
  fileNamesApi: {
    name: "fileNamesApi",
    urlEndpoint: "employee-request/file-name?", // requestType=update_employee_status
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 2,
  },
  getPICUsers: {
    name: "getPICUsers",
    urlEndpoint: "pic-user", // requestType=update_employee_status
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 2,
  },
  createPICUser: {
    name: "createPICUser",
    urlEndpoint: "pic-user",
    httpMethod: "POST",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: true,
    version: 2,
  },
  editPICUser: {
    name: "editPICUser",
    urlEndpoint: "pic-user/{picUserId}",
    httpMethod: "PATCH",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: true,
    version: 2,
  },
  deletePICUser: {
    name: "deletePICUser",
    urlEndpoint: "pic-user/{picUserId}",
    httpMethod: "DELETE",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: true,
  },
  filterInvoiceApi: {
    name: "filterInvoiceApi",
    urlEndpoint: "invoice?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: false,
    version: 2,
  },
  getAllAccessTypes: {
    name: "getAllAccessTypes",
    urlEndpoint: "pic-user/access-type",
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  getLimitRequestsApplication: {
    name: "getLimitRequestsApplication",
    urlEndpoint: "employer/risk/application?",
    httpMethod: "GET",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: true,
  },
  createNewLimitRequest: {
    name: "createNewLimitRequest",
    urlEndpoint: `employer/risk/application?`,
    httpMethod: "POST",
    hasQueryProps: true,
    isAuthenticated: true,
    hasNamedProps: true,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  uploadLimitDocument: {
    name: "uploadLimitDocument",
    urlEndpoint: `employer/risk/document`,
    httpMethod: "PUT",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  deleteRiskDocument: {
    name: "deleteRiskDocument",
    urlEndpoint: `employer/risk/document/{documentId}`,
    httpMethod: "DELETE",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
  submitLimitRequest: {
    name: "submitLimitRequest",
    urlEndpoint: "employer/risk/application",
    httpMethod: "PUT",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: false,
    stringifyRequest: false,
  },
  limitRequestDetails: {
    name: "limitRequestDetails",
    urlEndpoint: `employer/risk/application/{requestId}`,
    httpMethod: "GET",
    hasQueryProps: false,
    isAuthenticated: true,
    hasNamedProps: false,
    hasMixedProps: true,
    stringifyRequest: false,
  },
};

/**
 *
 * @param {string} name for the api name
 * @param {string} baseUrl for api base URL
 * @returns {string} as concatenated string with base url
 */
apiConfig.getApiUrl = (name, baseUrl) => baseUrl + apiConfig[name].urlEndpoint;

export default apiConfig;
