const sInternalError = "Internal error";
const sPayloadBadRequest = "Payload bad request";
const sBalanceNotEnough = "Balance not enough";
const sNotInOpenPeriod = "Not in open period";
const sAccountDisabled = "Account is disabled";
const sAccountValidationRuleFailed = "Account validation rules failed";
const sWalletType = "Wallet Type";
const sSalaryDisbursement = "Salary Disbursement";
const sEmployeeId = "Employee ID";
const sCutOffDate = "Cut Off Date";
const sCheckData = "Check Data";
const sEmpStatusChange = "Change in Employee Status";
const sEmpName = "Employee name";
const sVerificationStatus = "Verification Status";
const attentionText = "Attention!";
const okayText = "I Understand";
const initialOnboarding = "Employee Onboarding";
const manageTeam = "Manage Team";
const groupAndPayDate = "Group and Cut Off Date";
const transactionHistory = "Transaction History";
const invoice = "Invoice";
const timAkses = "Team access";
const en = {
  common: {
    language: "Change Language",
    selectAll: "Select All",
  },
  departments: {
    hr: "HR Department",
    finance: "Finance Department",
    ops: "Operations Department",
    am: "Account Management Department",
    others: "Other",
  },
  errorCodes: {
    documentIncorrect:
      "Upload Failed! There is some data in your document that is incorrect or missing. Please check again and re-upload",
    "GG-BENE-01": "Record conflict",
    "GG-BENE-02": sPayloadBadRequest,
    "GG-BENE-03": "Already exists",
    "GG-BENE-04": "Allowed enrolled beneficiary reached",
    "GG-BENE-05": "Deletion not allowed",
    "GG-CON-OTP-01": "Otp token required",
    "GG-CON-OTP-02": "Otp token invalid",
    "GG-CON-OTP-03": "Otp token already verified",
    "GG-CON-OTP-04": "Please try again or re-send the code",
    "GG-CON-OTP-05": "Otp token not found",
    "GG-CON-OTP-06": "Verification code is invalid.Please try again or re-send the code",
    "GG-CON-OTP-07": "Too much request for verification code. Please wait 1 hour to try again",
    "GG-CON-OTP-08": "Please wait 1 hour to try again",
    "GG-EMP-01": "Employee not found",
    "GG-EMP-02": "Employee status inactive",
    "GG-EMP-03": "Employee suspended",
    "GG-EMP-04": "Employee employer suspended",
    "GG-EMP-05": "Employee transaction is locked",
    "GG-EMP-06": "Employee employer inactive",
    "GG-EMP-09": "The company's EWA account has been permanently deactivated.",
    "GG-EMPYER-01": "Employer not found",
    "GG-EMPYER-02": "Employer status inactive",
    "GG-EMPYER-03": "Employer suspended",
    "GG-EMPYER-04": "Employer save failed",
    "GG-EMPYER-05": "Conficting Record",
    "GG-EMPYER-08": "This company's EWA account has been permanently deactivated.",
    "GG-EMPYER-LOGIN-01": "Email not valid",
    "GG-EMPYER-LOGIN-02": "Email or Password incorrect",
    "GG-EMPYER-LOGIN-03": "Employer suspended",
    "GG-EMPYER-LOGIN-04": "Employer status inactive",
    "GG-GEN-01": "Bad Request",
    "GG-GEN-02": "Too many request",
    "GG-GEN-03": "Api Not found",
    "GG-GEN-04": sInternalError,
    "GG-GEN-05": "Unauthorized JWT",
    "GG-GEN-06": "Unauthorized AppKey",
    "GG-GEN-07": "Unauthorized feature flag",
    "GG-GEN-08": "Response timeout",
    "GG-GEN-09": "Conficting Record",
    "GG-GEN-10": "Limit parameter error",
    "GG-GEN-11": "Skip parameter error",
    "GG-GEN-12": "Limit parameter invalid",
    "GG-GEN-13": "Skip parameter invalid",
    "GG-GEN-14": "Page number parameter invalid",
    "GG-GEN-15": "Limit should not be empty",
    "GG-GEN-16": "pageNo should not be empty",
    "GG-GEN-17": "Token Expired",
    "GG-GEN-18": "Record not found",
    "GG-GEN-20": "Only .xlsx file is allowed",
    "GG-GOLD-01": "Invalid Request Data",
    "GG-GOLD-02": "Profile Already Exist",
    "GG-GOLD-03": "Treasury Profile Not Found",
    "GG-OTP-01": "Phone number not valid",
    "GG-OTP-02": "Phone number is blocked",
    "GG-OTP-03": "Invalid app key",
    "GG-OTP-04": "Resend exhausted",
    "GG-OTP-05": "Otp sms service error",
    "GG-OTP-06": "Messaging Type invalid",
    "GG-OTP-07": "Phone number not registered",
    "GG-OTP-08": "Too much request for verification code. Please wait 1 hour to try again",
    "GG-PHONE-01": sPayloadBadRequest,
    "GG-PHONE-02": "Already exists",
    "GG-PHONE-03": "Allowed enrolled phone number reached",
    "GG-PHONE-04": "Deletion not allowed",
    "GG-PRODUCT-01": sInternalError,
    "GG-PRODUCT-02": "Product not found",
    "GG-PRODUCT-03": "Provider is feature flag",
    "GG-PRODUCT-04": "Product unavailable",
    "GG-PRODUCT-05": "Provided account type id is invalid",
    "GG-PRODUCT-06": "Payload bad request",
    "GG-SIGNUP-01": "Record already exists",
    "GG-SIGNUP-02": sInternalError,
    "GG-SIGNUP-03": sPayloadBadRequest,
    "GG-SIGNUP-04": "Invalid Employer Name",
    "GG-SIGNUP-05": "Invalid User Name",
    "GG-SIGNUP-06": "Invalid Phone Number payload",
    "GG-SIGNUP-07": "Invalid Identity type",
    "GG-SIGNUP-08": "Invalid Identity id",
    "GG-TRX-01": "User in lock period",
    "GG-TRX-02": "Employee transaction suspended",
    "GG-TRX-03": "Employee category blocked",
    "GG-TRX-04": "Account blocked for category",
    "GG-TRX-05": "Insufficient Balance",
    "GG-TRX-06": sInternalError,
    "GG-TRX-07": "Provider error",
    "GG-TRX-08": "Product not found",
    "GG-TRX-09": "Product unavailable",
    "GG-TRX-10": "Invalid Transaction Category",
    "GG-TRX-11": "Bank info not found",
    "GG-TRX-13": "Registered Bank not approved",
    "GG-TRX-14": "The requested amount is less than the minimum withdrawal",
    "GG-USER-01": "User not found",
    "GG-USER-02": "User status inactive",
    "GG-USER-03": "User suspended",
    "GG-USER-04": "Failed to retrieve user info",
    "GG-USER-05": "User KTP Not Found",
    "GG-VAL-01":
      "Employee Status is invalid. employeeStatus must be active, inactive, withdraw_suspended, and withdraw_accrual_suspended",
    "GG-VAL-02": "Invalid format for minAmount. Should be a number",
    "GG-VAL-03": "Invalid format for maxAmount. Should be a number",
    "GG-VAL-04": "Invalid format for payDate. Should be a string",
    "GG-VAL-05": "accountType must be EWA, POINTS, GOLD, SALARY_DISBURSEMENT, UANG_KANTOR, UANG_KAS, SD_OFFLINE",
    "GG-VAL-06": "Invalid format for employeeGroup. Should be an array of strings",
    "GG-VAL-07": "Invalid format for search. Should be a string",
    "GG-VAL-08": "Invalid format for employeeCode. Should be an array of string",
    "GG-VAL-09": "Invalid format for secondaryEmployeeGroup. Should be an array of string",
    "GG-VAL-10": "Invalid format for employeeId. Should be a number",
    "GG-VAL-11": "Invalid format for suspensionCategory. Should be string",
    "GG-VAL-12": "Invalid format for suspendedReason. Should be string",
    "GG-VAL-13": "Invalid email format",
    "GG-VAL-14": "Invalid format for fromTransactionDate. Should be a date string",
    "GG-VAL-15": "Invalid format for toTransactionDate. Should be a date string",
    "GG-VAL-16":
      "Invalid value for transaction status. Transaction status must be PENDING, COMPLETED, REJECTED, FAILED",
    "GG-VAL-17": "Invalid format for transactionCategoryId. Should be an array of string",
    "GG-VAL-18": "Invalid format for fromTransactionAmount. Should be a number",
    "GG-VAL-19": "Invalid format for toTransactionAmount. Should be a number",
    "GG-VAL-20": "Invalid format for partnerId. Should be a number",
    "GG-VAL-21": "Invalid format for employeeCode. Should be string",
    "GG-VAL-22":
      "Invalid format for employee request status. request status must be pending, completed, processing, failed or rejected",
    "GG-VAL-23": 'collar type must be either "BLUE" or "WHITE"',
    "GG-VAL-24": "Invalid format for userId. Should be number",
    "GG-VAL-25": "Invalid format for firstName. Should be string",
    "GG-VAL-26": "Invalid format for lastName. Should be string",
    "GG-VAL-27": "Invalid format for request type. Should be string",
    "GG-VAL-28": "Invalid format for birthDate. Should be string",
    "GG-VAL-29": "Invalid format for mobile number. Should be string",
    "GG-VAL-30": "Invalid format for gender. Should be string",
    "GG-VAL-31": "Invalid format for ktp number. Should be string",
    "GG-VAL-32": "Invalid format for joined at. Should be string",
    "GG-VAL-33": "Invalid format for group name. Should be string",
    "GG-VAL-34": "Invalid format for department name. Should be string",
    "GG-VAL-35": "Invalid format for designation name. Should be string",
    "GG-VAL-36":
      "Invalid format for salary type id. SalaryType must be MONTHLY, DAILY, WEEKLY, WORK_UNIT, HOURLY, BIWEEKLY, FIXED_MONTH, FIXED_LENGTH, NEW_WEEKLY, BI_MONTHLY",
    "GG-VAL-37": "Invalid format for salary amount. Should be number",
    "GG-VAL-38": "Invalid format for employees bank name. Should be string",
    "GG-VAL-39": "Invalid format for employees bank account no. Should be string",
    "GG-VAL-40": "Invalid format for beneficiary name. Should be string",
    "GG-VAL-41": "Invalid format for file name. Should be string",
    "GG-VAL-42": "Invalid format for employee name. Should be string",
    "GG-VAL-43":
      "Invalid format for employee request sort by. sort by must be employeeCode, employeeName or uploadedAt",
    "GG-VAL-44": "Invalid format for employee request sort order. sort order must be asc or desc",
    "GG-VAL-47": "Invalid value for gender. Gender must be MALE or FEMALE.",
    "GG-VAL-49": "Invalid Bank Code",
    "GG-VAL-50": "Invalid Bank Account Number",
    "GG-VAL-51": "Bank Account owner name mismatch",
    "GG-VAL-52": "Mobile Number Already taken",
    "GG-VAL-53": "Employee code already exist",
    "GG-VAL-54": "Kategori Penangguhan diperlukan saat status tidak aktif",
    "GG-VAL-55": "Alasan Penangguhan diperlukan saat Kategori Penangguhan adalah Eksternal Lainnya",
    "GG-VAL-56": "Employee mobile number cannot be empty",
    "GG-VAL-57": "Employee gender cannot be empty",
    "GG-VAL-58": "ID card number cannot be empty",
    "GG-VAL-59": "Employee ID cannot be empty",
    "GG-VAL-60": "Employee join date cannot be empty",
    "GG-VAL-61": "Bank name cannot be empty",
    "GG-VAL-62": "Bank account number cannot be empty",
    "GG-VAL-63": "Invalid employee status change. Reason must be empty if employee status is active",
    "GG-VAL-64": "Invalid employee status change. Detail reason must be empty if the reason is not Other",
    "GG-VAL-65": "Invalid employee status change. Detail reason must be empty if employee status is active",
    "GG-VAL-66": "Beneficiary name cannot be empty and cannot exceed 255 characters",
    "GG-VAL-67": "Birth date can't be a future date. Please select past date",
    "GG-VAL-68": "Joined at can't be a future date. Please select past date",
    "GG-VAL-69": "Employee name (firstName + lastName) must not exceed 50 characters",
    "GG-VAL-70": "Employee code must not exceed 25 characters",
    "GG-VAL-71": "Employee code must not any spaces",
    "GG-VAL-101": "Employee's Date of Birth must not be empty",
    "GG-EMP-REQ-01": "Invalid employee request type",
    "GG-EMP-REQ-02": "Employee Update request already exists",
    "GG-EMP-REQ-03": "No data to be updated",
    "GG-EMP-REQ-04": "Suspension Category not provided",
    "GG-EMP-REQ-05": "Employee request not found",
    "GG-EMP-REQ-06": "Failed to upload file",
    "GG-EMP-REQ-07": "Employer Id not found",
    "GG-EMP-REQ-08": "Employer not found",
    "GG-EMP-REQ-09": "Phone number is invalid",
    "GG-EMP-REQ-10": "Request for employee code or mobile number already exists under pending state.",
    "GG-EMP-REQ-11":
      "You cannot change the employee's status to active because your company's EWA account is currently temporarily deactivated. Please contact the GajiGesa team for further information.",
    "GG-EMP-REQ-12":
      "You cannot add new employees because your company's EWA account is currently temporarily deactivated. Please contact the GajiGesa team for further information.",
    "GG-KTP-01": "Ktp number is invalid",
    "GG-KTP-02": "Ktp name is invalid",
    "GG-KTP-03": "Ktp number length exceed limit",
    "GG-KTP-04": "Ktp name length exceed limit",
    "GG-KTP-05": "Ktp number length less than required length",
    "GG-RESEND-OTP-01": "Otp token required",
    // "GG-RESEND-OTP-02": "Otp token not found",
    "GG-RESEND-OTP-02": "Otp token resend",
    "GG-REASON-TYPE-01": "Reason type filter invalid",
    "GG-EXT-01": "Partner Side API Error",
    "GG-PARTNER-01": "employerIds required",
    "GG-PARTNER-02": "employerIds should be an array",
    "GG-PARTNER-03": "groupName should be an array",
    "GG-PARTNER-04": "secondaryGroupName should be an array",
    "GG-PARTNER-05": "Withdeleted should be boolean",
    "GG-PARTNER-06": "page should be a number",
    "GG-PARTNER-07": "page number invalid",
    "GG-PARTNER-08": "limit should be a number",
    "GG-PARTNER-09": "limit invalid",
    "GG-INVOICE-01": "No record found for RN generation",
    "GG-INVOICE-02": "Group Name is required",
    "GG-PC-01": "Payment cycle Not Found",
    "GG-TRXH-01": "Status not an array",
    "GG-TRXH-02": "Payment cycle id is not a string",
    "GG-REQ-WD-01": "Account invalid",
    "GG-REQ-WD-02": "Reason invalid",
    "GG-REQ-WD-03": sBalanceNotEnough,
    "GG-REQ-WD-04": sNotInOpenPeriod,
    "GG-REQ-WD-05": sAccountDisabled,
    "GG-REQ-WD-06": sAccountValidationRuleFailed,
    "GG-REQ-WD-07": "Bank Account Number not enrolled / approved",
    "GG-REQ-WD-08": "Invalid Bank Account Number",
    "GG-REQ-WD-09": "Invalid Request Amount",
    "GG-REQ-WD-10": "Invalid Reason Id",
    "GG-REQ-WD-11": "Invalid Account Number",
    "GG-REQ-WD-12": "Invalid Referral Code",
    "GG-CON-WD-01": "Transaction not found",
    "GG-CON-WD-02": "Account invalid",
    "GG-CON-WD-03": sBalanceNotEnough,
    "GG-CON-WD-04": sNotInOpenPeriod,
    "GG-CON-WD-05": sAccountDisabled,
    "GG-CON-WD-06": sAccountValidationRuleFailed,
    "GG-RF-01": "Invalid referral Code",
    "GG-RF-02": "Referral Code Not Applicable. Since its not first tnx'",
    "GG-POINT-01": "Point Account does not exists",
    "GG-SF-01": "Salary profile has not been registered",
    "GG-LOAN-01": "Invalid product code for loan",
    "GG-LOAN-02": "Employee not eligible for loan product",
    "GG-LOAN-03": "Loan code must be a string",
    "GG-LOAN-04": "Loan code must not be empty",
    "GG-LOAN-05": "Loan application already exists",
    "GG-LOAN-06": "Invalid application Id",
    "GG-LOAN-07": "Application ID must be a valid number and not null",
    "GG-CON-BP-01": "Provider Id not found",
    "GG-CON-BP-02": "The request has expired. Please ask again",
    "GG-REQ-BP-01": "Account not found",
    "GG-REQ-BP-02": "Reason invalid",
    "GG-REQ-BP-03": sBalanceNotEnough,
    "GG-REQ-BP-04": sNotInOpenPeriod,
    "GG-REQ-BP-05": sAccountDisabled,
    "GG-REQ-BP-06": sAccountValidationRuleFailed,
    "GG-REQ-BP-09": "Invalid Request Amount",
    "GG-REQ-BP-10": "Invalid Reason Id",
    "GG-REQ-BP-11": "Invalid Account Number",
    "GG-REQ-BP-12": "Invalid Referral Code",
    "GG-REQ-BP-13": "Invalid reason description",
    "GG-REQ-BP-14": "Invalid postpaid amount from ayoconnect",
    "GG-REQ-BP-15": "Sorry, the system is experiencing a problem. Please try again in a moment",
    "GG-REQ-BP-16": "Billing is not yet available",
    "GG-REQ-BP-17": "Customer ID number is not registered",
    "GG-REQ-BP-18": "This number is not postpaid",
    "GG-REQ-BP-19": "Invalid Transaction BP Catergory",
    "GG-REQ-BP-20": "Transaction has failed, please try again",
    "GG-REQ-BP-21": "Vouchers have expired. Look forward to regular updates, OK!",
    "GG-REQ-BP-22": "Request Transaction already confirmed",
    "GG-SD-REQ-01": "You are not allowed to perform this action",
    "GG-SD-REQ-02": "Minimum 1 record is required to process",
    "GG-SD-REQ-03": "Upload Failed! Files must not exceed 1000 lines/row. Please check back and re-upload",
    "GG-SD-REQ-04": "expectedSalaryDisbursementDate is mandatory",
    "GG-SD-REQ-05": "Only future dates allowed for expectedSalaryDisbursementDate",
    "GG-SD-REQ-06": "Invalid salary disbursement request status",
    "GG-SD-REQ-07": "Headers in the file are not matching with the template provided",
    "GG-SD-REQ-08": "File contains extra header columns not required",
    "GG-SD-REQ-09": "Request Not Found",
    "GG-SD-REQ-10": "Invalid Id Format",
    "GG-SD-REQ-11": "Record validation status value is incorrect",
    "GG-SD-REQ-12": "RequestId is required to download failed records",
    "GG-SD-REQ-13": "The request has already been raised",
    "GG-GRP-PAY-01": "Group must be empty. Please contact the GajiGesa team for group creation.",
    "GG-GRP-PAY-02":
      "Group cannot be empty. Please select a Group from the options available in Group and Cut Off Date.",
    "GG-GRP-PAY-03": "Invalid group. Please select a Group from the options available in Group and Cut Off Date.",
    "GG-GRP-PAY-04":
      "Cut off date cannot be empty. Please select a Date from the options available in Group and Cut Off Date.",
    "GG-GRP-PAY-05": "Invalid cut off date. Please select a Date from the options available in Group and Cut Off Date.",
    "GG-EMP-PIC-01": "PIC exists for given user id",
    "GG-EMP-PIC-02": "Invalid PIC Name. Should be in string format",
    "GG-EMP-PIC-03": "PIC Name is required.",
    "GG-EMP-PIC-04": "Invalid PIC Mobile Number. Should be in Indonesian Mobile Number format",
    "GG-EMP-PIC-05": "PIC Mobile Number is required.",
    "GG-EMP-PIC-06": "Invalid PIC Department Name. Should be in string format",
    "GG-EMP-PIC-07": "PIC Department Name is required.",
    "GG-EMP-PIC-08": "Invalid PIC Access Type Id.",
    "GG-EMP-PIC-09": "PIC Access Type is required.",
    "GG-EMP-PIC-10": "PIC User not found.",
    "GG-EMP-PIC-11": "User exists for given mobile number.",
    "GG-EMP-PIC-12": "User exists for given mobile number.",
    "GG-EMP-INV-01": "Account filter invalid. Must be 0 to 6",
    "GG-EMP-INV-02": 'Status filter invalid. Must be "PAID", "DUE", "OVERDUE"',
    "GG-EMP-INV-03": "Start Date filter invalid",
    "GG-EMP-INV-04": "End Date filter invalid",
    "GG-EMP-INV-05": "Due Date filter invalid",
    "GG-RSK-APP-01": "Submit Application request type invalid",
    "GG-RSK-APP-02": "You already have a pending request.",
    "GG-RSK-APP-LIMIT-01": "Submit Application EWA limit invalod",
    "GG-RSK-APP-LIMIT-02": "Submit Application SD limit invalod",
    "GG-RSK-APP-DOC-01": "Submit Application with rejected documents not allowed",
    "GG-RSK-APP-DOC-02": "Submit Application mandatory documents incomplete",
    "GG-RSK-APP-DOC-03": "Submit document key invalid",
    "GG-RSK-APP-DOC-04": "Submit document file type invalid",
    "GG-RSK-APP-DOC-05": "Submit document file size invalid. Not greater than 25MB",
    "GG-RSK-APP-DOC-06": "Submit document exists with status not Uploaded | Rejected",
    basicValidation: {
      lettersOnly: "Please use only letters",
      tooLong: "Too long",
      tooShort: "Too short",
      numbersOnly: "Use numbers only",
      noMinus: "No minus symbol",
      noZeros: "Cannot Send 0",
      invalidPhone: "Invalid phone number length",
      phoneExists: "Phone number already exists",
      missingFields: "Please fill this field",
      properFormat: "The cellphone number format is incorrect",
    },
  },
  home: {
    welcome: "GajiGesa Employer Dashboard",
    hello: "Hello",
    summary: {
      ringkasan: "Team Summary",
      tipe: sWalletType,
      totalEmployees: "Total Employees",
      employee: "Employees",
      active: "Active",
      inactive: "Non Active",
      ewa: "EWA",
      uangKantor: "Uang Kantor",
      salaryDisbursement: sSalaryDisbursement,
    },
    list: {
      title: "Team List",
      header: {
        no: "No",
        employeeId: sEmployeeId,
        employeeName: sEmpName,
        walletType: sWalletType,
        payDate: sCutOffDate,
        withdrawLimit: "Withdrawal Limit",
        group: "Group",
        status: "Status",
        reason: "Reason",
        walletToolTip: "Pockets of funds owned by your employees",
        payDateToolTip: "Last Date for Employee Salary Calculation.",
        withdrawToolTip: "Salary limit that can be withdrawn by employees via EWA GajiGesa",
        salaryAmt: "Salary Amount",
        ktpNumber: "KTP Number",
        phoneNumber: "Phone Number",
        bankAccountNumber: "Account Number",
      },
      rows: {
        active: "Active",
        inactive: "Inactive",
        Aktif: "Active",
        "Non Aktif": "Inactive",
        endMonth: "End of Month",
        MALE: "Male",
        FEMALE: "Female",
        BLUE: "Blue Collar",
        WHITE: "White Collar",
        withdraw_suspended: "Inactive",
        withdraw_accrual_suspended: "Inactive",
      },
    },
    filter: {
      search: "Search",
      searchPlaceholder: "Input employee name or employee ID",
      filter: "Filter",
      download: "Download Data",
      add: "Add Employee",
    },
    export: {
      success: "Email saved. The data will be sent to the email in about 5 minutes.",
    },
    statusChange: {
      success: "Status atau Alasan berhasil diubah",
    },
    progressBox: {
      title: "Employee Data Process",
      description: "Check the status of additions and changes to employee data here",
      button: sCheckData,
    },
    statusCheckBox: {
      title: sEmpStatusChange,
      description: 'Check the status of changes to "Employee Status" here',
      button: sCheckData,
    },
    statusReason: {
      "API/ System Errors": "API/ System Errors",
      "Business Closure": "Business Closure",
      "Employee Request": "Employee Request",
      "End Contract with Employer": "End Contract with Employer",
      "End Contract with GG": "End Contract with GG",
      "GG Action": "GG Action",
      "Hit Employer Limit": "Hit Employer Limit",
      "HR Issues": "HR Issues",
      "Late Payment": "Late Payment",
      "Moved Employer within GG": "Moved Employer within GG",
      "Moved to Competitor": "Moved to Competitor",
      "Other Internal": "Other Internal",
      "Permanent Deactivation": "Permanent Deactivation",
      "Proactive Deactivation": "Proactive Deactivation",
      "SDO/ST Only": "Only for Payroll",
      Temporary: "Temporary",
      "Test/ Dummy Account": "Test/ Dummy Account",
      "Close Cycle": "Close Cycle",
      "Data Review": "Data Review",
      Dismissal: "Dismissal",
      "Duplicate/ Incorrect Data": "Duplicate/ Incorrect Data",
      "Employer Action": "Employer Action",
      "Other External": "Other External",
      Fraud: "Fraud",
      Resignation: "Resignation",
      "Waiting Go-Live": "Waiting for Activation",
    },
  },
  login: {
    welcome: "Welcome!",
    warning: "Access from your desktop for a better experience using this website",
    phone: "Phone Number",
    otp: "Verification code sent to ",
    phonePlaceholder: "Enter the phone number in the format 628xxxxx",
    error: "Invalid phone number",
    otpError: "Invalid OTP",
    whatsapp: "Send OTP via Whatsapp",
    sms: "Send OTP via SMS",
    signIn: "Sign in",
    noReceive: "Didn't receive verification code?",
    resend: "Resend code",
    or: "or",
    changePhone: "Change phone number",
    copyright: "© 2023 GajiGesa. All rights reserved.",
    otpResent: "OTP Resent. Try again",
  },
  basicValidation: {
    lettersOnly: "Please use only letters",
    tooLong: "Too long",
    tooShort: "Too short",
    numbersOnly: "Use numbers only",
    noMinus: "No minus symbol",
    noZeros: "Cannot Send 0",
    invalidPhone: "Invalid phone number length",
    phoneExists: "Phone number already exists",
    missingFields: "Please fill this field",
    properFormat: "The cellphone number format is incorrect",
    youCanSelectOnlyFutureDates: "Sorry, you can only choose a date for tomorrow or later dates",
    pleaseSelectGroupFirst: "Sorry, please select a group first",
    noSpaces: "There should be no space on employee ID",
  },
  progressBox: {
    title: "Employee Data Process",
    description: "Check the status of additions and changes to employee data here",
    button: sCheckData,
  },
  roles: {
    EMPLOYEE: manageTeam,
    "GROUP-PAYDATE": groupAndPayDate,
    TRANSACTION: transactionHistory,
    SD: sSalaryDisbursement,
    INVOICE: invoice,
    "TEAM-ACCESS": timAkses,
    LIMIT: "Limit Request",
  },
  sideBar: {
    initialOnboarding,
    manageTeam,
    groupAndPayDate,
    transactionHistory,
    invoice,
    whatsappSupport: "Whatsapp Support",
    logOut: "Logout",
    singleCreate: "Add One Employee",
    inProgress: "Data Under Verification",
    inStatusCheck: sEmpStatusChange,
    changeEmployeeData: "Change Employee Data",
    bulkCreate: "Add Several Employees",
    bulkUpdate: "Update data for several employees",
    bulkUpdateStatus: "Change Multiple Employee Status",
    salaryDisbursement: sSalaryDisbursement,
    sdScheduling: "Salary Disbursement Scheduling",
    sdScheduledData: "Data For Scheduling",
    iobBulkCreate: "Upload Data",
    timAkses,
    addPIC: "Add PIC",
    limitRequest: "Limit Request",
  },
  employeeCreation: {
    single: {
      personalData: "Data Pribadi",
      personalDataDesc: "Data Pribadi adalah informasi pribadi karyawan Anda.",
      employeeData: "Data Karyawan",
      employeeDataDesc:
        "Data Karyawan adalah informasi detail tentang karyawan Anda meliputi informasi jenis karyawan, pembayaran gaji dan jadwal kerja.",
      info: "Perhatian",
      infoDesc:
        "Tanggal absen terakhir karyawan yang disepakati oleh perusahaan dan GajiGesa. Pastikan mengisi dengan benar.",
      fullName: "Full Name (Required)",
      phone: "Phone Number (Required)",
      gender: "Gender (Required)",
      birthDate: "Date of Birth",
      ktp: "KTP Number (Required)",
      employeeId: "Employee ID (Required)",
      employeeType: "Employee Type (Required)",
      bankName: "Bank Name (Required)",
      beneficiaryName: "Beneficiary Name (Required)",
      salaryType: "Salary Type (Required)",
      payDate: "Pay Date (Required)",
      dateJoined: "Joining Date (Required)",
      group: "Group",
      bankNumber: "Bank Account Number (Required)",
      status: "Employee Status (Required)",
      salary: "Salary Amount (Required)",
      endOfMonth: "End of month",
      selectDate: "Choose a salary date",
      submit: "Submit",
      cancel: "Cancel",
      success: "Employee Creation Successful",
      pending: "New employee data is awaiting verification",
      error: "Changes to employee data are rejected",
      reason: "Reason (Required)",
      chooseDetailedReason: "If you choose other, write detailed reasons (required)",
      suspensionCategoryPlaceholder: "Select Reason",
      suspendedReasonPlaceholder: "Enter Reason Details",
      selectGroup: "Select Group",
      selectGroupPlaceholder: "Search Groups",
      noGroup: "No Groups",
      maxWithdrawal: "Withdrawal Limit (To be filled by the GajiGesa team)",
      salType: "Monthly",
    },
    bulk: {
      addSeveralEmployees: "Add Several employees",
      addSeveralDesc: "You can register several employees at once easily",
      followTheseSteps: "Follow these steps :",
      selectDocumentTemplate: "Before uploading a document, make sure you have used the template we provide",
      afterDownloadingFillDetailsAndExport:
        'After downloading the template, make sure you fill in the data according to the template and export it with the type ".XLSX" before uploading it with a maximum size of 25 MB',
      pleaseUploadFileInFormat: 'Please upload your document. Files that can be uploaded are in "XLSX" format.',
      downloadTemplate: "Download Template",
      changeDocument: "Change Document",
      or: "Or",
      wipe: "Wipe",
      clickHereToUpload: "Click here to upload",
      orDragFilesToThisArea: "or Drag Files To This Area",
      maxFileSize25mb: "Maximum File Size 25 MB.",
      maxFileSizeExceeded: "The file you uploaded exceeds the maximum file size. Please check and try again",
      incorrectFileFormat: "The file format you uploaded is incorrect. Please check and try again",
      cancel: "Cancel",
      submit: "Submit",
      uploadingPleaseWait: "Uploading, please wait…",
    },
    BLUE: "Blue Collar",
    WHITE: "White Collar",
  },
  employeeUpdation: {
    single: {
      update: "Changes to employee data are approved",
      pending: "Changes to employee data are waiting for a decision",
      rejected: "Changes to employee data are rejected",
      processing: "Changes to employee data are awaiting verification",
      warningTitle: "In Verification",
      WarningDescription:
        "Some data is in the process of being verified by the GajiGesa team. We will notify you when verification is complete",
      dormantTitle: attentionText,
      dormantText:
        "This employee has been inactive for more than 30 days. If you want to reactivate them, please ensure that all their data is up-to-date/current.",
    },
    bulk: {
      changingEmployeeDataAtOnce: "Changing Employee Data at Once",
      bulkUpdateDesc: "You can change the data of many employees at once",
    },
    bulkUpdateStatus: {
      title: "Change Multiple Employee Status",
      desc: 'You can simultaneously change many employee statuses from "Active to Inactive" and vice versa',
    },
  },
  inProgress: {
    title: "Data Under Verification",
    waiting: "Some new employee data is waiting to be verified",
    downloadDataAndCheckForErrors: "Download data and check for errors",
    table: {
      header: {
        title: "Employee Data",
        no: "No",
        rowExcel: "Row Excel",
        employeeId: sEmployeeId,
        employeeName: sEmpName,
        uploadDate: "Uploaded On",
        fileName: "Nama File",
        status: sVerificationStatus,
        note: "Notes",
        actionType: "Action Type",
        employeeStatus: "Employee Status",
        reason: "Reason",
      },
    },
    processingFileToast: "File upload is in progress",
    fileNameCopy: "File name successfully copied.",
    searchPlaceholder: "Search for Employee Name or ID",
  },
  timAkses: {
    tableHeaderTitle: "List of team members",
    headerTitle: "Access team",
    listHeader: "List of team members",
    tableHeader: {
      name: "Employee name",
      phone: "Mobile phone number",
      roles: "Department",
      actions: "Delete access",
      groups: "Groups",
    },
    addUser: {
      btnTitle: "Add members",
      namePlaceHolder: "Enter the employee's name",
      createSuccessToast: "Members were successfully added!",
      editSuccessToast: "Successful change!",
    },
    departments: "Departments",
    rolesField: "Select Access Rights",
    groupField: "Select Group",
    validation: {
      required: {
        department: "Please select atleast 1 department",
        access: "Please select atleast 1 role",
        group: "Please select atleast 1 group",
      },
    },
  },
  inStatusCheck: {
    title: sEmpStatusChange,
    table: {
      header: {
        title: "Employee Data",
        no: "No",
        employeeId: sEmployeeId,
        employeeName: sEmpName,
        uploadDate: "Uploaded On",
        fileName: "Nama File",
        status: sVerificationStatus,
        note: "Notes",
      },
    },
  },
  toolTip: {
    totalEmployees: "Total number of employees who are registered in GajiGesa",
    totalActiveEmployees: "Employees who can conduct transactions in GajiGesa",
    totalSuspendEmployees: "Employees who cannot conduct transactions in GajiGesa",
    ewa: "Employee EWA Account",
    uangKantor: "Accounts used as office operating fund",
    salaryDisbursement: "Employee salary account received through GajiGesa salary disbursement",
    walletType: "",
    payDate: "",
    withdrawalLimit: "",
    documentIsBeingUploaded: "document is being uploaded…",
    uploadFailedDueToSystemError: "Upload failed due to system error. Please try again",
    documentUploadedSuccessfully: "document uploaded successfully",
    suspensionCategory: `To permanently disable the option to change the reason: \nTermination of Employment and Resignation.`,
  },
  modal: {
    export: {
      title: "Enter email to download",
      description: "Your downloaded file will be sent to your registered email in approximately 5 minutes.",
      placeholder: "example : agni@alamatemail.com",
      button: "Save & Send",
      buttonLoad: "Please Wait…",
    },
    create: {
      confirm: "Add Employee Confirmation",
      confirmDesc: "Please make sure the data is correct before submitting",
      submit: "Submit",
      cancel: "Cancel",
      limit: "Create limit confirmation",
    },
    edit: {
      title:
        "Make sure all data is correct.If you change the cellphone number, immediately inform the relevant employees to enter Gajigesa using a new mobile number",
      confirm: "Save Changes",
    },
    delete: {
      cancel: "Cancel",
      confirm: "Happy",
      title: "Delete access",
      description: "You are sure you want to delete access for ",
    },
    update: {
      confirm: "Save Changes?",
      confirmDesc:
        `Do you want to deactivate this employee? ` +
        `\n` +
        `Note: Employees who have been deactivated can only be reactivated through the GajiGesa Team.`,
      confirmDescSusCategory: "Do you want to change the 'Reason for Inactive Status'?",
      submit: "Yes, Save",
      cancel: "Cancel",
      confirmLimitDesc: "Do you want to update the limit request?",
    },
    cancel: {
      title: "Leave Page?",
      desc: "Are you sure you want to leave this page? The data you have entered will be lost.",
      stay: "Stay here",
      leave: "Leave it",
    },
    login: {
      title: "Resend Verification Code",
      description: "Please select a method to resend your verification code",
    },
    bulk: {
      confirm: "Confirm Add Several Employees",
      confirmDesc: "Make sure the documents uploaded are correct.",
      leavePage: "Leave Page",
      areYouSureYouWantToLeave: "Are you sure you want to leave this page? The data you have entered will be lost.",
      leave: "Leave",
      stayHere: "Stay Here",
      iobConfirm: "Confirm Employee Onboarding",
      summaryBox: "Employee Onboarding Data Process",
    },
    sd: {
      confirm: "Scheduling Confirmation",
      confirmDesc: "We will check the uploaded data first",
      submit: "Continue",
      cancel: "Cancel",
      submitSDScheduleDesc: "We will process the scheduling.",
      submitScheduleWarning: "Make sure the data is correct, data cannot be changed once scheduled.",
      wipeSDSchedule: "Erase Data?",
      wipeSDScheduleDesc: "Are you sure you want to delete data that is ready to be scheduled?",
      dontDelete: "Don't delete",
      yesDelete: "Yes Delete",
    },
    suspension: {
      status: "Status:",
      active: "Active",
      inactive: "Inactive",
      reasonForInactivity: "Reason for Inactivity (Required):",
      writeDetailedReason: "If you choose other, write detailed reasons: (Mandatory)",
      cancel: "Cancel",
      save: "Save",
      placeholder: "Enter Reason Details",
    },
    status: {
      selectOrChangeReason: "Select or change reasons",
      changeStatus: "Change status",
    },
  },
  filter: {
    title: "Filter Based",
    walletType: sWalletType,
    status: "Status",
    group: "Group",
    payDate: sCutOffDate,
    withdrawLimit: "Withdraw Limit",
    reset: "Reset",
    save: "Save",
    actionType: "Action Type",
    fileName: "File Name",
    withdrawalAmount: "Withdrawl Amount",
    startingFrom: "Starting From (Rp)",
    upTo: "Up To (Rp)",
    transactionStatus: "Transaction Status",
    purposeOfWithdrawal: "Purpose Of Withdrawal",
    searchHere: "Search Here",
    noData: "No Data",
  },
  transactionPage: {
    table: {
      no: "No",
      withdrawalDate: "Withdrawal Date",
      employeeId: sEmployeeId,
      employeeName: sEmpName,
      group: "Group",
      withdrawalAmount: "Withdrawal Amount",
      purposeOfWithdrawal: "Purpose of Withdrawal",
      status: "Status",
    },

    transaction: "Transaction",
    downloadDataSuccess: "Download Data Success to Email Id",
    notes: "Notes",
    dataListedIsBasedOnSalaryWithdrawlDataInPayrollCycle:
      "The data listed is based on salary withdrawal data in the payroll cycle for this period or yours choose",
    transactionDate: "Transaction date",
    dataSummary: "Data Summary",
    totalSalaryWithdrawals: "Total Salary Withdrawals",
    numberOfTransactions: "Number Of Transactions",
    totalEmployeesTransacting: "Total Employees Transacting",
    viewTransactions: "View Transactions",
    enterEmployeeNameOrID: "Enter Employee Name or ID",
    search: "Search",
    downloadData: "Download Data",
    downloadAllData: "Download All Data",
    downloadDataWithFilters: "Download Data With Filters",
    downloadThisMonthsData: "Download this month's data",
    REJECTED: "REJECTED",
    PENDING: "PENDING",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
    CANCELLED: "CANCELLED",
    NOT_STARTED: "NOT STARTED",
    SUCCESS: "SUCCESS",
    all: "All of them",
  },
  employeeForm: {
    withdrawalText: "Withdrawal Limit (To be filled by the GajiGesa team)",
    withdrawalNotify: "Contact the GajiGesa Team if you want to change the Withdrawal Limit",
    statusEmployerWS:
      "Cannot change employee status to active because your company's EWA account is temporarily deactivated.",
  },
  groupPaydate: {
    columnOne: "No",
    columnTwo: "Group Name",
    columnThree: sCutOffDate,
    title: "Group & Cut Off Date",
    cutOffTitle: "Group & Cut Off Date",
    noticeTitle: "Important Notes",
    noticeOne: "Check the Group and Cut Off Date when adding or modifying employee data.",
    noticeTwo:
      "If your Company/Employee does not have a group, please select 'No Group' when adding or modifying employee data.",
    noticeThree: "Contact the GajiGesa team if you want to add or modify Groups.",
    tableText: "List of Groups & Cut Off Dates",
  },

  salaryDisbursement: {
    title: sSalaryDisbursement,
    noData: "Salary Disbursement Scheduling will be displayed here",
    scheduling: {
      title: sSalaryDisbursement,
      description: "Starting scheduling employee salary disbursement here",
    },
    SDScheduleBox: {
      title: "Salary Disbursement Scheduling",
      description: "Start scheduling employee salary disbursement here",
      button: "Schedule",
    },
    SDRequestBox: {
      title: "Data For Scheduling",
      description: "Check data that is ready to be scheduled/rejected here",
      button: sCheckData,
      warningBeforeSendingSdRequest:
        "Before scheduling, make sure the selected employee data is correct. Employee data cannot be changed once the data is scheduled!",
      submitScheduling: "Submit Scheduling",
      wipe: "Wipe",
      totalNominal: "Total Nominal",
      selectedData: "Selected Data",
      downloadTitle: "Download Template",
      downloadDesc: 'The file you download will be sent to your registered email in less than "5 Minutes".',
      downloadPlaceholder: "agni@gajigesa.com",
      downloadButton: "Save and Send",
      checkRejectedDataByDownloadingData:
        "Check rejected data by downloading data. If you leave this page, the rejected data will be immediately deleted",
    },
    SDDetailsBox: {
      title: "Bailout Scheduling",
      breadcrumb: "Scheduling Number",
      placeholder: "Enter your cellphone number, employee name or account number",
    },
    list: {
      title: "Scheduling List",
      header: {
        no: "No",
        schedulingNumber: "Scheduling Number",
        uploadDate: "Upload Date",
        totalNominal: "Total Nominal",
        verificationStatus: " Verification Status",
        rejectionReason: "Rejection Reason",
        disbursementStatus: "Disbursement Status",
        scheduledOn: "Scheduled On",
        download: "Download",
        employeeId: sEmployeeId,
        employeeName: sEmpName,
        grup: "Group",
        mobilePhoneNumber: "Mobile phone number",
        BankName: "Bank Name",
        accountNumber: "Account number",
        nameOfAccountOwner: "Name of Account Owner",
        transferNews: "Transfer News",
        companyEmail: "Company Email",
        notes: "Notes",
      },
    },
    steps: {
      makeSureSelectTheTemplate: "Before uploading a document, make sure you have used the template we provide",
      selectExpectedSchedulingDate:
        "Select the expected scheduling date: Scheduling can only be done at least 1 day after the data upload process",
      ensureTemplateDateAndFileType:
        'Before uploading, make sure the data you fill in matches the template and export the entire thing in the ".XLSX" type.',
      pleaseUploadYourDocument: 'Please upload your document. Files that can be uploaded are in "XLSX" format.',
    },
    table: {
      header: {
        title: "Scheduling Data List",
      },
      verifiedTab: "Data Can Be Processed",
      rejectedTab: "Data Rejected",
    },
    tooltip: {
      submitScheduleSuccess: "All data is correct and ready to be scheduled",
      documentIncorrect:
        "Upload Failed! There is some data in your document that is incorrect or missing. Please check again and re-upload",
      dataIsDeleted: "Data has been deleted!",
      pleaseSelectEmployees: "Please select employees first to schedule Salary Disbursement",
      pleaseSelectEmployeesToDelete: "Please select employees first to delete from Salary Disbursement",
    },
    modal: {
      title: "Confirm data sending",
      description: 'The file you download will be sent to your registered email in less than "5 Minutes".',
      placeholder: "agni@gajigesa.com",
      button: "Save and Send",
    },
  },
  errorFallBack: {
    title: "Oops, there was an error!",
    desc: "Don't worry, we're checking for you. Please close and open first return to this page",
    refresh: "Refresh Page",
    sideBarTitle: "Cannot display menu!",
    sideBarDesc: "Please refresh or close first and reopen this page",
    checkYourInternetConnection: "Try checking your internet connection again and refreshing the page",
  },
  status: {
    completed: "Approved",
    pending: "Wait",
    rejected: "Rejected",
    failed: "Fail",
  },
  banner: {
    wsBannerText:
      "Your company's EWA account is temporarily disabled. Please contact the GajiGesa team for further information. Currently, you can only perform Employee Data Addition, Employee Data Changes, and Change of Deactivation Reasons.",
  },
  modalAlerts: {
    ws: {
      title: attentionText,
      content:
        "Your company's EWA account is temporarily deactivated, please contact the GajiGesa team for further information. Currently, you can only perform \n 1. Adding Employee Data \n 2. Modifying Employee Data \n 3. Changing Deactivation Reasons",
      btnText: okayText,
    },
    dormant: {
      title: attentionText,
      content1: `This employee has been inactive for more than`,
      content2: ` 30 days`,
      content3: `. If you want to reactivate them, please ensure all their data is up-to-date and current`,
      btnText: okayText,
    },
    iob: {
      title: attentionText,
      content:
        "Hello HR partner, welcome to the GajiGesa Employer Dashboard. Before starting, please upload employee data as per the data approved in the KYB process.",
      btnText: okayText,
    },
    iob_congo: {
      title: "Congratulations!",
      content: "Your Employee Onboarding Process is complete! Now you can access all HR Dashboard features",
      btnText: "Okay",
    },
    noAccess: {
      title: "Attention!",
      content: "You don't have access to this page.Please contact the Gajigesa Team or your company's HR",
      btnText: "Understand",
    },
  },
  iobProcessData: {
    bulkBannerTitle: "Upload Data",
    bulkBannerDesc: "Start the Employee Onboarding process by uploading the necessary data here",
    bulkUploadBtn: "Start",
  },
  invoices: {
    pageTitle: "Invoices",
    table: {
      listHeader: "List Invoice",
      raiseDispute: "Contact",
      view: "View",
      viewAndPay: "Pay & View",
      header: {
        group: "Group",
        deliveryDate: "Delivery Date",
        startDate: "Start Date",
        dateEnd: "End Date",
        dueDate: "Due Date",
        totalInvoiceValue: "Total Invoice Value",
        totalJumlah: "Total",
        totalFee: "Total Fee",
        totalTransaction: "Total Transactions",
        status: "Status",
        actions: "Actions",
        invoiceNumber: "Invoice Number",
      },
    },
    dateEndFilter: "End Date",
    payConfirmation: "Confirmation",
    payModalDesc:
      "You will be redirected to the payment page where you can check the invoice details and make the payment.",
    payModalCancel: "Back",
    payModalSubmit: "Continue",
    disputeModalTitle: "Problem in invoice?",
    disputeModalDesc: " For any complaints/issues on our invoice please write an email to :",
    disputeModalCancel: "Cancel",
    disputeModalSubmit: "Send Email",
  },
  filterContent: {
    specificDate: "Specific Date",
  },
  limitRequests: {
    currentEWALimit: "Current EWA Limit",
    currentSDLimit: "Current SD Limit",
    totalEWALimit: "Total EWA Limit",
    totalSDLimit: "Total SD Limit",
    requests: "All Requests",
    limitIncreaseRequests: "Limit Increase",
    newApplication: "New Application",
    table: {
      id: "Request ID",
      appliedOn: "Applied On",
      lastUpdate: "Last Update",
      currentStatus: "Current Status",
      category: "Category",
    },
    status: {
      pending: "Pending",
      approved: "Approved",
      rejected: "Rejected",
      needsCheck: "Needs Check",
      awaitingUpload: "Awaiting Upload",
      uploaded: "Confirmed",
    },
  },
  limitRequest: {
    errorMessages: {
      ewaRequired: "EWA limit is required",
      sdRequired: "SD limit is required",
      minimumVal: "Minimum is 1",
      maximumVal: "Maximum is 1 trillion",
      mustBeNumber: "Must be a number",
      noLeadingZeros: "No leading zeros allowed",
      reasonRequired: "Reason for limit is required",
      reasonMinCharacters: "Reason must be at least 5 characters",
    },
    table: {
      headers: {
        docType: "Document Type",
        fileName: "File Name",
        file: "File",
        status: "Status",
      },
    },
    mandatoryDocuments: "Mandatory Documents",
    additionalDocuments: "Additional Documents",
    decision: "Decision",
    decisionHeader: "Your Requested Details Are",
    mandatoryDocList: {
      L3MBS: "Last 3 Month Bank Statement",
      CAWC: "Contract aggreement with clients",
      LD: "Latest Deed",
      SKM: "SK Menkuham",
      IDCM: "ID Card of Management",
    },
    additionalDocList: {
      FSLY: "Financial Statement (Last Year)",
      FSCY: "Financial Statement (Current Year)",
      CP: "Cashflow Projection",
      SIHRA: "Sample Invoices along with handover approval by respective authorities",
      AED: "Additional Employee Data",
      BPJS: "BPJS Payment (Transfer Proof and F2APU)",
    },
    decisionList: {
      requestId: "Request ID",
      requestType: "Request Type for Limit",
      requestDate: "Request Date",
      requestEWALimit: "Requested EWA Limit",
      requestSDLimit: "Requested SD Limit",
      mandatoryDocumentDecision: "Mandatory Document Review",
      additionalDocumentDecision: "Additional Document Review",
      finalDecision: "Final Decision",
      totalEWARequestedLimit: "The total limit of the proposed EWA",
      totalSDRequestedLimit: "Total Elementary Limit Submitted",
      totalEWApprovedLimit: "Total Approved EWA Limit",
      totalSDApprovedLimit: "Total Approved SD Limit",
      lastUpdatedAt: "Last Updated At",
    },
    submitButton: "Save",
  },
};

export default en;
