import LimitInputForm from "atoms/LimitInputForm";
import { Input, Text, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import colors from "theme/colors";
import { numberWithDots } from "utils/helpers";
import { AiFillPlusCircle } from "react-icons/ai";
import PropTypes from "prop-types";

function EWALimitCurrentAndRequired(props) {
  const { limit, currencySymbol, requestLimits } = props;
  const { t } = useTranslation("common");

  return (
    <View flexDirection="row" marginTop="16px">
      <View marginRight="4px" width="30%">
        <Text variant="xs" color={colors.neutral.darkMist}>
          {t("limitRequests.currentEWALimit")}
        </Text>
        <Input isDisabled value={currencySymbol + numberWithDots(limit)} textAlign="right" />
      </View>

      <AiFillPlusCircle
        size="32px"
        style={{ alignSelf: "center", marginTop: "16px", marginLeft: "16px", marginRight: "16px" }}
      />
      <LimitInputForm
        testID="ewaLimit"
        sText="EWA"
        name="ewaLimit"
        currentValue={limit}
        defaultValue={requestLimits}
        total="totalEWALimit"
        transTotalKey="limitRequests.totalEWALimit"
      />
    </View>
  );
}

EWALimitCurrentAndRequired.propTypes = {
  limit: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  requestLimits: PropTypes.string.isRequired,
};

export default EWALimitCurrentAndRequired;
