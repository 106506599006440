import React from "react";
import { View, Text, Box } from "native-base";
import PropTypes from "prop-types";
import { LIMIT_FILE_STATUS, LIMIT_REQUEST_APPLICATION_STATUS } from "global/constants";
import { useTranslation } from "react-i18next";
import colors from "theme/colors";

export function getStylesForRoles(status, arrayToCheck) {
  if (!status) return arrayToCheck[0];
  return arrayToCheck.filter((item) => item.status === status)[0];
}

export function shouldRenderNotes(notes, status) {
  return (
    notes &&
    status !== LIMIT_FILE_STATUS.UPLOADED && (
      <Text variant="sm-bold" mt={2} maxWidth={200} color={colors.error.rose}>
        {notes}
      </Text>
    )
  );
}

function StatusComponent(props) {
  const { status, arrayToCheck, notes } = props;
  const roleStyle = getStylesForRoles(status, arrayToCheck);
  const { t } = useTranslation("common");

  return (
    <Box alignItems="center">
      <View px={3} py={1} borderRadius={18} maxWidth={199} backgroundColor={roleStyle?.bgColor}>
        <Text variant="sm-bold" color={roleStyle?.textColor}>
          {t(roleStyle?.text)}
        </Text>
      </View>

      {shouldRenderNotes(notes, status)}
    </Box>
  );
}

StatusComponent.propTypes = {
  status: PropTypes.string,
};
StatusComponent.defaultProps = {
  status: LIMIT_REQUEST_APPLICATION_STATUS.PENDING,
};

export default StatusComponent;
