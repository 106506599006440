import React, { Suspense } from "react";
import { View, Button } from "native-base";
import PropTypes from "prop-types";
import { CREATE_MODALS, LIMIT_FILE_STATUS } from "global/constants";
import { getStore } from "store/storeUtils";
import colors from "theme/colors";

const CreateModalLazy = React.lazy(() => import("molecules/CreateModal"));

// Helper function to check if a document passes the necessary checks
export function getDocumentsPassCheck(doc) {
  const validStatuses = [LIMIT_FILE_STATUS.UPLOADED, LIMIT_FILE_STATUS.PENDING, LIMIT_FILE_STATUS.APPROVED];
  const invalidStatuses = [
    LIMIT_FILE_STATUS.REJECTED,
    LIMIT_FILE_STATUS.AWAITING_UPLOAD,
    LIMIT_FILE_STATUS.NEEDS_CHECK,
  ];

  return validStatuses.includes(doc.status) && !invalidStatuses.includes(doc.status);
}

// Helper function to check if all documents in a category are valid
export function areAllDocumentsValid(documents) {
  return Array.isArray(documents) && documents.every(getDocumentsPassCheck);
}

// Helper function to check if limits are valid
export function areLimitsValid(requestType, values, errors) {
  let limitsValid = true;

  if (["0", "2"].includes(requestType)) {
    limitsValid = limitsValid && values.ewaLimit && !errors.ewaLimit;
  }

  if (["1", "2"].includes(requestType)) {
    limitsValid = limitsValid && values.sdLimit && !errors.sdLimit;
  }

  return limitsValid;
}

// DecisionTabActions Component
const DecisionTabActions = ({ requestType, isAdditionalRequired, values, errors, handleSubmit, hide }) => {
  const { modal, setModal } = getStore();

  // Check if all necessary conditions are met for submission
  const allMandatoryUploaded = areAllDocumentsValid(values.mandatoryDocuments);
  const allAdditionalUploaded = areAllDocumentsValid(values.additionalDocuments);
  const limitsValid = areLimitsValid(requestType, values, errors);

  const canSubmit =
    requestType === "0"
      ? allMandatoryUploaded && limitsValid && (!isAdditionalRequired || allAdditionalUploaded)
      : allMandatoryUploaded && allAdditionalUploaded && limitsValid;

  if (hide) return null;

  return (
    <View marginTop="16px">
      <Button
        type="submit"
        disabled={!canSubmit}
        onPress={() =>
          setModal({
            ...modal,
            type: CREATE_MODALS.limitCreate,
          })
        }
        style={{ backgroundColor: canSubmit ? colors.secondary.orchidBlue : colors.neutral.darkMist }}
      >
        Submit
      </Button>
      <Suspense fallback={<div>Loading...</div>}>
        <CreateModalLazy handleSendData={handleSubmit} />
      </Suspense>
    </View>
  );
};

// PropTypes for the component
DecisionTabActions.propTypes = {
  requestType: PropTypes.string.isRequired,
  isAdditionalRequired: PropTypes.bool,
  values: PropTypes.shape({
    mandatoryDocuments: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string.isRequired,
      }),
    ),
    additionalDocuments: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string.isRequired,
      }),
    ),
    ewaLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sdLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  errors: {},
  handleSubmit: PropTypes.func.isRequired,
  hide: PropTypes.bool,
};

DecisionTabActions.defaultProps = {
  isAdditionalRequired: false,
  errors: {},
  hide: false,
};

export default DecisionTabActions;
