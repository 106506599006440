import LimitInputForm from "atoms/LimitInputForm";
import { Input, Text, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillPlusCircle } from "react-icons/ai";
import colors from "theme/colors";
import { numberWithDots } from "utils/helpers";

function SDLimitCurrentAndRequired(props) {
  const { limit, currencySymbol, requestLimits } = props;
  const { t } = useTranslation("common");
  return (
    <View flexDirection="row" marginTop="16px">
      <View marginRight="4px" width="30%">
        <Text variant="xs" color={colors.neutral.darkMist}>
          {t("limitRequests.currentSDLimit")}
        </Text>
        <Input isDisabled value={currencySymbol + numberWithDots(limit)} textAlign="right" />
      </View>

      <AiFillPlusCircle
        size="32px"
        style={{ alignSelf: "center", marginTop: "16px", marginLeft: "16px", marginRight: "16px" }}
      />
      <LimitInputForm
        testID="sdLimit"
        sText="SD"
        name="sdLimit"
        currentValue={limit}
        defaultValue={requestLimits}
        total="totalSDLimit"
        transTotalKey="limitRequests.totalSDLimit"
      />
    </View>
  );
}

export default SDLimitCurrentAndRequired;
