import { TableRow, TableBody, TableHead, Table, Paper, TableContainer } from "@mui/material";
import TableCellContent from "atoms/TableCellContent";
import colors from "theme/colors";
import { View } from "native-base";
import FileUploader from "atoms/FileUploader";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import StatusComponent from "atoms/StatusComponent";
import { LIMIT_FILE_STATUS, LIMIT_FILE_STYLES } from "global/constants";

export function fileHandler(docs, setFieldValue, name) {
  return (key, action, documentId, filePath) => {
    const updatedDocs = docs.map((doc) =>
      doc.key === key
        ? {
            ...doc,
            status: action === "upload" ? LIMIT_FILE_STATUS.UPLOADED : LIMIT_FILE_STATUS.AWAITING_UPLOAD,
            id: documentId || null,
            fileName: action === "delete" ? null : doc.selectedFileName, // retains selectedFileName if not deleted or is uploaded
            selectedFileName: null,
            filePath: action === "upload" ? filePath : doc.filePath, // retains prvious filePaths if not uploaded
          }
        : doc,
    );
    setFieldValue(name, updatedDocs);
  };
}

function TableMandatoryDocuments({ name, reqId, isAdditionalRequired, isRejected, isApproved }) {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation("common");

  // Get the documents array from formik values based on the passed name prop
  const docs = values[name] || [];

  const handleFileNameChange = (documentKey, fileName) => {
    const updatedDocs = docs.map((doc) => (doc.key === documentKey ? { ...doc, selectedFileName: fileName } : doc));
    setFieldValue(name, updatedDocs);
  };

  const handleFileUpload = fileHandler(docs, setFieldValue, name);

  return (
    <TableContainer
      style={{
        marginTop: 24,
      }}
      component={Paper}
    >
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableHead
          style={{
            backgroundColor: colors.neutral.mist,
          }}
        >
          <TableRow>
            <TableCellContent variant="xs-bold" align="center">
              {t("limitRequest.table.headers.docType")}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="center">
              {t("limitRequest.table.headers.fileName")}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="center">
              {t("limitRequest.table.headers.file")}
            </TableCellContent>
            <TableCellContent variant="xs-bold" align="center">
              {t("limitRequest.table.headers.status")}
            </TableCellContent>
          </TableRow>
        </TableHead>
        <TableBody>
          {docs.map((item) => (
            <TableRow key={item.key}>
              <TableCellContent variant="xs-normal" align="left">
                {item.name}
              </TableCellContent>
              <TableCellContent variant="xs-normal" align="center">
                {item.selectedFileName || item.fileName || "No file"}
              </TableCellContent>
              <TableCellContent variant="xs-normal" align="right">
                <View flexDirection="row">
                  <FileUploader
                    docKey={item.key}
                    requestId={reqId}
                    onFileNameChange={(fileName) => handleFileNameChange(item.key, fileName)}
                    onUpload={(documentId, filePath) => handleFileUpload(item.key, "upload", documentId, filePath)}
                    onDelete={() => handleFileUpload(item.key, "delete")}
                    fileStatus={item.status}
                    documentId={item.id}
                    filePath={item?.filePath}
                    isAdditionalRequired={isAdditionalRequired}
                    isRejected={isRejected}
                    isApproved={isApproved}
                    selectedFileName={item?.selectedFileName} // to keep track of selectedFile for update constraints purpose
                  />
                </View>
              </TableCellContent>
              <TableCellContent variant="xs-normal" align="center">
                <StatusComponent
                  status={item?.status}
                  notes={item?.additionalComments}
                  arrayToCheck={LIMIT_FILE_STYLES}
                />
              </TableCellContent>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableMandatoryDocuments.propTypes = {
  name: PropTypes.string.isRequired, // "mandatoryDocuments" or "additionalDocuments"
};

export default TableMandatoryDocuments;
