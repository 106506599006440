import { Box, Button, Text, useToast, View } from "native-base";
import React, { useState, useRef, useEffect } from "react";
import { AiOutlineEye, AiOutlineUpload, AiOutlineDelete, AiOutlineImport } from "react-icons/ai";
import PropTypes from "prop-types";
import { useMutation } from "@tanstack/react-query";
import { deleteRiskDocument, uploadRiskDocuments } from "api/apiCall";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showErrorToast } from "utils/utilities";
import { getStore } from "store/storeUtils";
import { LIMIT_FILE_STATUS, LIMIT_REQUEST_APPLICATION_STATUS } from "global/constants";
import colors from "theme/colors";

export function onDeleteSuccessHandler(setFile, filePath, setFileUrl, setError, fileInputRef, onDelete) {
  return () => {
    setFile(null);
    if (filePath) {
      setFileUrl(filePath);
    } else {
      setFileUrl(null); // Explicit reset if needed
    }
    setError("");
    if (fileInputRef?.current) {
      const inputElement = fileInputRef.current;
      inputElement.value = "";
    }
    onDelete();
  };
}

export function iconClickHandler(fileInputRef) {
  return () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
}
export function truncateFileNames(fileName) {
  const extensionIndex = fileName.lastIndexOf("."); // Find the last period for the extension

  if (extensionIndex === -1) {
    // Case: No extension
    if (fileName.length > 13) {
      const truncatedName = fileName.substring(0, 10); // Truncate to 10 chars
      return `${truncatedName}...`;
    }
    return fileName;
  }

  // Case: Split name and extension
  const name = fileName.substring(0, extensionIndex); // Get the name part
  const extension = fileName.substring(extensionIndex); // Get the extension part

  // Ensure the total length is <= 13
  if (fileName.length > 13) {
    const truncatedName = name.substring(0, 10); // Truncate name to 10 chars
    return `${truncatedName}...${extension}`; // Add ellipsis before the extension
  }

  return fileName; // Return original if <= 13 chars
}

export function fileUploadSuccessHandler(fileUploadSuccess, fileUploadData, onUpload, fileUrl, setFile, fileInputRef) {
  if (fileUploadSuccess && fileUploadData) {
    onUpload(fileUploadData, fileUrl);
    setFile(null);
    if (fileInputRef?.current) {
      const inputElement = fileInputRef.current;
      inputElement.value = "";
    }
  }
}

export function fileChangeHandler(file, setError, setFile, setFileUrl, onFileNameChange) {
  return (event) => {
    const selectedFile = event.target.files[0];

    // If the user cancels the file dialog, do nothing
    if (!selectedFile) {
      if (file) {
        setError(""); // Clear any previous errors
      } else {
        setError("No file selected"); // Show error only if no file was previously selected
      }
      return;
    }

    // Validate file type
    const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
    if (!allowedTypes.includes(selectedFile.type)) {
      setError("File type not supported. Please upload a PNG, JPEG, or PDF file.");
      return;
    }

    // Validate file size (5MB max)
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (selectedFile.size > maxSize) {
      setError("File size exceeds the 5MB limit.");
      return;
    }

    // Clear errors and update file state
    setError("");
    setFile(selectedFile);

    // Create a URL for file preview
    const previewUrl = URL.createObjectURL(selectedFile);
    setFileUrl(previewUrl);

    // Truncate the file name to 10 characters and preserve the extension
    const truncatedFileName = truncateFileNames(selectedFile.name);
    onFileNameChange(truncatedFileName);
  };
}

export function uploadHandler(file, requestId, docKey, handleFileUploadApi) {
  return () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("requestId", requestId);
    formData.append("documentKey", docKey);
    handleFileUploadApi(formData);
  };
}

export function previewHandler(fileUrl, setError) {
  return () => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    } else {
      setError("No file available for preview.");
    }
  };
}

export function shouldRenderUploadButton(canShowUpLoadedBtn, handleIconClick) {
  return (
    canShowUpLoadedBtn && (
      <Button
        variant="solid"
        borderRadius="5px"
        onPress={handleIconClick}
        // flex={1}
        leftIcon={
          <AiOutlineImport
            size="16px"
            style={{ marginRight: "8px" }}
            testID="btnFileSelect"
            title="Click to upload a file"
            color={colors.neutral.cotton}
          />
        }
        _text={{ variant: "xs" }}
      >
        Select/Change File
      </Button>
    )
  );
}

export function shouldRenderPreview(isFileAvailable, handlePreview) {
  return (
    isFileAvailable && (
      <Button
        variant="solid"
        borderRadius="5px"
        backgroundColor={colors.secondary.daybreak}
        flex={1}
        onPress={handlePreview}
        marginLeft="5px"
        leftIcon={
          <AiOutlineEye
            size="16px"
            style={{ marginRight: "8px", cursor: "pointer" }}
            testID="btnFileView"
            title="Click to preview the file"
            color={colors.neutral.cotton}
          />
        }
        _text={{ variant: "xs" }}
      >
        View
      </Button>
    )
  );
}

export function shouldRenderDelete(canShowDeleteButton, handleDelete) {
  return (
    canShowDeleteButton && (
      <Button
        backgroundColor={colors.primary.carnation}
        borderRadius="5px"
        onPress={handleDelete}
        flex={1}
        marginLeft="5px"
        _text={{ variant: "xs" }}
        leftIcon={
          <AiOutlineDelete
            size="16px"
            style={{ marginRight: "8px", cursor: "pointer", color: colors.neutral.cotton }}
            testID="btnFileDelete"
            title="Click to delete the file"
            color={colors.neutral.cotton}
          />
        }
      >
        Delete
      </Button>
    )
  );
}

export function shouldRenderSelectedUpload(file, handleUpload) {
  return (
    file && (
      <Button
        onPress={handleUpload}
        borderRadius="5px"
        flex={1}
        backgroundColor={colors.variants.clover}
        marginTop="5px"
        _text={{ variant: "xs-normal" }}
        leftIcon={
          <AiOutlineUpload
            size="16px"
            style={{ marginRight: "8px", cursor: "pointer", color: colors.neutral.cotton }}
            testID="btnFileUpload"
            title="Click to Upload the file"
            color={colors.neutral.cotton}
          />
        }
      >
        Upload
      </Button>
    )
  );
}

const FileUploader = ({
  onUpload,
  onDelete,
  onFileNameChange,
  requestId,
  docKey,
  fileStatus,
  documentId,
  filePath,
  isAdditionalRequired,
  isRejected,
  isApproved,
  selectedFileName,
}) => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { resetLoginData } = getStore();
  const toast = useToast();
  const {
    isSuccess: fileUploadSuccess,
    data: fileUploadData,
    mutate: handleFileUploadApi,
  } = useMutation({
    mutationFn: (payload) => uploadRiskDocuments(payload),
    onError: (error) => {
      showErrorToast({ error, toast, navigate, resetLoginData });
    },
  });
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  useEffect(() => {
    if (filePath) setFileUrl(filePath);
  }, [filePath]);

  useEffect(() => {
    return () => {
      if (selectedFileName && !fileUploadSuccess) onFileNameChange(null);
    };
  }, [selectedFileName, fileUploadSuccess]);

  const onDeleteSuccess = onDeleteSuccessHandler(setFile, filePath, setFileUrl, setError, fileInputRef, onDelete);
  const { mutate: deletRiskDocumentApi } = useMutation({
    mutationFn: (payload) => deleteRiskDocument(payload),
    onSuccess: onDeleteSuccess,
    onError: (error) => {
      showErrorToast({ error, toast, navigate, resetLoginData });
    },
  });

  const handleIconClick = iconClickHandler(fileInputRef);

  useEffect(() => {
    fileUploadSuccessHandler(fileUploadSuccess, fileUploadData, onUpload, fileUrl, setFile, fileInputRef);
  }, [fileUploadSuccess, fileUploadData]);

  const handleFileChange = fileChangeHandler(file, setError, setFile, setFileUrl, onFileNameChange);

  const handleUpload = uploadHandler(file, requestId, docKey, handleFileUploadApi);

  const handlePreview = previewHandler(fileUrl, setError);

  const handleDelete = () => {
    if (documentId && !selectedFileName)
      deletRiskDocumentApi(documentId); // if selectedFile doesnt exist and document id is present then call
    else onDeleteSuccess();
  };

  const isCreateWithAwaitingOrUploaded =
    type === "create" &&
    (fileStatus === LIMIT_FILE_STATUS.AWAITING_UPLOAD || fileStatus === LIMIT_FILE_STATUS.UPLOADED);

  const isUploadedOrAdditionalRequired = isAdditionalRequired || fileStatus === LIMIT_FILE_STATUS.UPLOADED;
  const isExcludedStatus = [LIMIT_FILE_STATUS.PENDING, LIMIT_FILE_STATUS.APPROVED, LIMIT_FILE_STATUS.REJECTED].includes(
    fileStatus,
  );

  const isViewWithAdditionalOrUploaded = type === "view" && isUploadedOrAdditionalRequired && !isExcludedStatus;
  const isRejectedOrNeedsCheck =
    fileStatus === LIMIT_FILE_STATUS.REJECTED || fileStatus === LIMIT_FILE_STATUS.NEEDS_CHECK;

  const canShowUpLoadedBtn =
    (isCreateWithAwaitingOrUploaded || isViewWithAdditionalOrUploaded || isRejectedOrNeedsCheck) &&
    !(isRejected || isApproved);

  const isFileUploadedRejectedOrNeedsCheckWithDocument =
    (fileStatus === LIMIT_FILE_STATUS.UPLOADED ||
      fileStatus === LIMIT_REQUEST_APPLICATION_STATUS.REJECTED ||
      fileStatus === LIMIT_FILE_STATUS.NEEDS_CHECK) &&
    documentId;

  const canShowDeleteButton = file || (isFileUploadedRejectedOrNeedsCheckWithDocument && type === "create");

  const isFileAvailable = file || (!file && fileStatus !== LIMIT_FILE_STATUS.AWAITING_UPLOAD);

  return (
    <View flex={1} justifyContent="space-evenly">
      <View flexDirection="row">
        {/* Icon for triggering file input */}
        {shouldRenderUploadButton(canShowUpLoadedBtn, handleIconClick)}
        {/* Icon for previewing the selected file */}
        {shouldRenderPreview(isFileAvailable, handlePreview)}
        {/* Icon for deleting the selected file */}
        {shouldRenderDelete(canShowDeleteButton, handleDelete)}
        {/* Hidden file input */}
        <input
          type="file"
          accept=".png,.jpeg,.jpg,.pdf"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        {/* Display selected file or errors */}

        {/* Upload button */}
      </View>
      {shouldRenderSelectedUpload(file, handleUpload)}
      {error && (
        <Box alignItems="center">
          <Text width={140} textAlign="center" color={colors.error.rose}>
            {error}
          </Text>
        </Box>
      )}
    </View>
  );
};

FileUploader.propTypes = {
  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
  onFileNameChange: PropTypes.func,
};
FileUploader.defaultProps = {
  onUpload: () => {},
  onDelete: () => {},
  onFileNameChange: () => {},
};

export default FileUploader;
