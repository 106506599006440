/* Zustand middleware, generates a hook called useStore to get state and call setter functions  */

import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import routeData from "global/routes";

export const initialState = {
  /* AUTH */
  loginData: {
    phoneNumber: "",
    otp: "",
    otpType: "SMS",
    loginSuccess: false,
    token: "",
    isLoggedIn: false,
  },

  /* PROFILE */
  userData: {
    userName: "",
  },

  /* EMPLOYEE DATA */
  employeeGroups: [],

  /* ROUTING */
  globalRoute: routeData?.[0],

  /* UTILS */
  modal: { type: "", data: {} },

  /* FILE HANDLING STATES */
  noOfFilesUploading: 0,
  isFileUploading: false,
  meData: {},
  modalPayload: {},
  employerConfigData: null,
};

export const createSlice = (set) => ({
  ...initialState,

  /* AUTH */
  setLoginData: (loginData) => set({ loginData }),
  resetLoginData: () => set({ ...initialState }),

  /* PROFILE */
  setUserData: (userData) => set({ userData }),

  /* EMPLOYEE DATA */
  setEmployeeGroups: (employeeGroups) => set({ employeeGroups }),

  /* ROUTING */
  setGlobalRoute: (globalRoute) => set({ globalRoute }),

  /* UTILS */
  setModal: (modal) => set({ modal }),
  resetModal: () => set({ modal: { ...initialState.modal } }),

  /* To Determine no of files currently being uploaded */
  setNoOfFilesUploading: (value) => set({ noOfFilesUploading: value }),

  /* To set if a file upload is going on */
  setIsFileUploading: (value) => set({ isFileUploading: value }),
  setMeApiData: (meData) => set({ meData }),
  setModalAlert: (modalPayload) => set({ modalPayload }),
  setEmployerDataConfig: (employerConfigData) => set({ employerConfigData }),
});

function createSliceParam() {
  return (set) => ({
    ...createSlice(set),
  });
}

function getStorageParam() {
  return {
    name: "gg-web",
    storage: createJSONStorage(() => window.localStorage),
  };
}

const persistParameter = persist(createSliceParam(), getStorageParam());

const useStore = create(persistParameter);

export default useStore;
