import React, { useEffect, useState } from "react";
import InputCurrency from "atoms/InputCurrency";
import { Input, Text, View } from "native-base";
import { numberWithDots } from "utils/helpers";
import PropTypes from "prop-types";
import { useField } from "formik";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import config from "config";
import tenantConfig from "tenantConfig";
import { LuEqual } from "react-icons/lu";

export function onLimitBlurHandler(limitField, limitHelpers) {
  return () => {
    const numericValue = parseInt(String(limitField.value).replace(/\./g, ""), 10);

    if (!isNaN(numericValue)) {
      // Convert to dotted/formatted string
      limitHelpers.setValue(numberWithDots(String(numericValue)));
    } else {
      // If invalid, revert to empty
      limitHelpers.setValue("");
    }
    limitHelpers.setTouched(true);
  };
}

export function onLimitChangeHandler(limitHelpers, setTotalValue, currentValue) {
  return (newValueOrEvent) => {
    const newValue =
      typeof newValueOrEvent === "object" && newValueOrEvent?.target ? newValueOrEvent.target.value : newValueOrEvent;

    // Update Formik state for 'name'
    limitHelpers.setValue(newValue || "");

    // Calculate and update the local total
    setTotalValue(parseInt(currentValue, 10) + parseInt(newValue || "0", 10));
  };
}

export function onDefaultValueHandler(defaultValue, limitHelpers, setTotalValue, currentValue) {
  if (defaultValue) {
    limitHelpers.setValue(defaultValue);
    setTotalValue(parseInt(currentValue || "0", 10) + parseInt(defaultValue, 10));
  }
}

function LimitInputForm({ testID, sText, name, currentValue, total, transTotalKey, defaultValue }) {
  const { currencySymbol } = tenantConfig[config.COUNTRY_CODE];
  const { t } = useTranslation("common");
  // 1. Hook for the limit field
  const [limitField, limitMeta, limitHelpers] = useField(name);
  // 2. Hook for the total field
  const [, , totalHelpers] = useField(total);

  const limitIsError = limitMeta.touched && limitMeta.error;

  // Local state for the combined total
  const [totalValue, setTotalValue] = useState("");

  useEffect(() => {
    onDefaultValueHandler(defaultValue, limitHelpers, setTotalValue, currentValue);
  }, [defaultValue, currentValue]);

  // Handle changes to the limit input
  const onLimitChange = onLimitChangeHandler(limitHelpers, setTotalValue, currentValue);

  // Whenever totalValue changes, update the 'total' field in Formik
  useEffect(() => {
    if (totalValue && totalValue > 0) {
      // Use the helper from the second useField hook
      totalHelpers.setValue(totalValue);
    }
  }, [totalValue, totalHelpers]);

  // Handle blur event for the limit input
  const onLimitBlur = onLimitBlurHandler(limitField, limitHelpers);

  return (
    <View flexDirection="row">
      <View marginRight="4px">
        <Text variant="xs" color={colors.neutral.darkMist}>
          Input {sText === "EWA" ? "EWA" : "SD"} Limit{" "}
        </Text>
        <InputCurrency
          input={limitField.value || ""}
          onValueChange={onLimitChange}
          onValueBlur={onLimitBlur}
          testID={testID}
          disabled={defaultValue}
          customStyles={{
            borderColor: limitIsError ? colors.error.rose : colors.neutral.charcoal,
            textAlign: "right",
          }}
          withDots={false}
        />
        {limitIsError && <Text style={{ color: "red", marginLeft: 8 }}>{limitMeta.error}</Text>}
      </View>
      <LuEqual
        size="32px"
        style={{ alignSelf: "center", marginTop: "16px", marginLeft: "16px", marginRight: "16px" }}
      />
      <View>
        <Text variant="xs-bold" textAlign="left" color={colors.secondary.darkOrchid} alignSelf="stretch">
          {t(transTotalKey)}
        </Text>
        <Input disabled value={currencySymbol + numberWithDots(totalValue)}></Input>
      </View>
    </View>
  );
}

LimitInputForm.propTypes = {
  testID: PropTypes.string.isRequired,
  sText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  total: PropTypes.string.isRequired,
};

export default LimitInputForm;
