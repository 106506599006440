import React from "react";
import { Box, HStack, Text } from "native-base";
import { Link } from "react-router-dom";
import colors from "theme/colors";
import { useTranslation } from "react-i18next";
import { CREATE_MODALS } from "global/constants";
import { getStore } from "store/storeUtils";

const BreadcrumbItem = ({ name, link, isFileUploading, showModal }) => {
  const { t } = useTranslation("common");

  const renderLinkOrText = () => {
    if (!isFileUploading) {
      return (
        <Link to={link} color={colors.secondary.orchid}>
          <Text variant="xs" color={colors.secondary.orchid}>
            {t(name)}
          </Text>
        </Link>
      );
    }
    return (
      <Text
        variant="xs"
        color={colors.secondary.orchid}
        textDecorationLine="underline"
        textDecorationStyle="solid"
        onPress={() => showModal(link)}
      >
        {t(name)}
      </Text>
    );
  };

  return (
    <HStack space="2">
      {renderLinkOrText()}
      <Text variant="xs" mt="2px">
        {">"}
      </Text>
    </HStack>
  );
};

const Breadcrumbs = ({ currentPage = "", style = {}, parentScreen = [] }) => {
  const { setModal, isFileUploading } = getStore();
  const { t } = useTranslation("common");
  const parentScreens = [{ name: "sideBar.manageTeam", link: "/" }];

  const showModalForDisableUpload = (screenLink = "/") => {
    setModal({
      type: CREATE_MODALS.fileIsUploading,
      screenLink,
    });
  };

  return (
    <Box
      h="70px"
      bg={colors.neutral.cotton}
      justifyContent="center"
      px="25px"
      mx="10px"
      my="15px"
      borderRadius={12}
      style={style}
    >
      <HStack space="2" flexWrap="wrap" alignItems="center" textAlign="center">
        {(parentScreen.length != 0 ? parentScreen : parentScreens).map((screen) => (
          <BreadcrumbItem
            key={`${screen.link}-${screen.name}`}
            name={screen.name}
            link={screen.link}
            isFileUploading={isFileUploading}
            showModal={showModalForDisableUpload}
          />
        ))}
        <Text variant="xs" mt="2px">
          {t(`${currentPage}`)}
        </Text>
      </HStack>
    </Box>
  );
};

export default React.memo(Breadcrumbs);
