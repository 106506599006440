import { useFormikContext } from "formik";
import { Button, View } from "native-base";
import { getCanProceedForAdditonalDocuments } from "pages/LimitRequest";
import colors from "theme/colors";

const AdditionalTabActions = ({ setValue, requestType, isAdditionalRequired }) => {
  const { values } = useFormikContext();

  const canProceed = getCanProceedForAdditonalDocuments(values.additionalDocuments, requestType, isAdditionalRequired);

  return (
    <View marginTop="16px">
      <Button
        onPress={() => setValue(2)}
        disabled={!canProceed}
        style={{ backgroundColor: canProceed ? colors.secondary.orchidBlue : colors.neutral.darkMist }}
      >
        Next
      </Button>
    </View>
  );
};

export default AdditionalTabActions;
