import { BULK_CREATE, BULK_UPDATE, BULK_UPDATE_STATUS, SINGLE_CREATE } from "global/paths";
import colors from "theme/colors";
import config from "config";
import { ArtAccountSuspended, ArtCongratulations, ArtEmptySeat, ArtFilling, ArtNoAccess } from "assets";

export const homeListInactiveRows = "home.list.rows.inactive";

export const WHATSAPP_URL =
  "https://api.whatsapp.com/send?text=Halo, tim Support GajiGesa. Saya memerlukan bantuan untuk menyelesaikan masalah di HR Dashboard. Mohon bantuannya, ya🙏&phone=628111778961";

export const TEAM_MODALS = {
  changeStatus: "CHANGE_STATUS",
  singleUpdate: "SINGLE_UPDATE",
  export: "EXPORT",
  filter: "FILTER",
};

export const TXN_MODALS = {
  export: "EXPORT_DATA",
  filter: "FILTER_DATA",
};

export const CREATE_MODALS = {
  singleCreate: "continue",
  singleUpdate: "update",
  singleCreateCancel: "cancel",
  export: "CREATE_EXPORT",
  bulkCreate: "BULK_CREATE",
  iobBulkCreate: "IOB_BULK_CREATE",
  fileIsUploading: "FILE_IS_UPLOADING",
  sdScheduling: "SALARY_DISBURSEMENT_SCHEDULING",
  sdSubmitSchedule: "SUBMIT_SD_SCHEDULing",
  WipeSdScheduling: "WIPE_SD_SCHEDULING",
  exportAllSDRequests: "EXPORT_ALL_SD_REQUESTS",
  exportSDDataDitolak: "EXPORT_SD_DATA_DITOLAK",
  singlePIC: "SINGLE_PIC_CREATE",
  invoicesDispute: "INVOICE_DISPUTE",
  invoicesPay: "INVOICE_PAY",
  picEdit: "PIC_EDIT",
  limitCreate: "LIMIT_CREATE",
  limitUpdate: "LIMIT_UPDATE",
};

export const DELETE_MODALS = {
  deletePIC: "deletePIC",
};

export const LOGIN_MODALS = {
  reset: "RESET",
};

export const ACCOUNT_TYPE_FILTER = {
  ewa: {
    code: "EWA",
    name: "Akun Gaji",
  },
  uangKantor: {
    code: "UANG_KANTOR",
    name: "Uang Kantor",
  },
  salaryDisbursement: {
    code: "SALARY_DISBURSEMENT",
    name: "Penalangan Gaji",
  },
  points: {
    code: "POINTS",
    name: "POINTS Account",
  },
};

export const CREATE_OPTIONS = [
  {
    id: 1,
    name: "sideBar.singleCreate",
    path: SINGLE_CREATE,
    amplitudeEvent: "single_employee_creation_clicked",
    validate: true,
  },
  {
    id: 2,
    name: "sideBar.bulkCreate",
    path: BULK_CREATE,
    amplitudeEvent: "bulk_employee_creation_clicked",
    validate: true,
  },
  {
    id: 3,
    name: "sideBar.bulkUpdate",
    path: BULK_UPDATE,
    amplitudeEvent: "bulk_employee_update_clicked",
    validate: false,
  },
  {
    id: 4,
    name: "sideBar.bulkUpdateStatus",
    path: BULK_UPDATE_STATUS,
    amplitudeEvent: "bulk_employee_update_status_clicked",
    validate: false,
  },
];

export const GENDER_OPTIONS = (t) => [
  { id: 1, name: t("home.list.rows.MALE"), data: "MALE" },
  { id: 2, name: t("home.list.rows.FEMALE"), data: "FEMALE" },
];

export const STATUS_OPTIONS = [
  { id: 1, name: "Aktif", data: "active" },
  { id: 2, name: "Non Aktif", data: "withdraw_suspended" },
];

export const VERIFY_STATUS_OPTIONS = (t) => {
  return [
    {
      id: 2,
      name: t("status.completed"),
      data: "completed",
      color: colors.success.cactus,
    },
    {
      id: 3,
      name: t("status.pending"),
      data: "pending",
      color: colors.warning.sunflower,
    },
    {
      id: 4,
      name: t("status.rejected"),
      data: "rejected",
      color: colors.error.rose,
    },
    {
      id: 5,
      name: t("status.failed"),
      data: "failed",
      color: colors.error.rose,
    },
  ];
};

export const STATUS_TYPES = {
  requestStatus: [
    {
      id: 1,
      name: "Disetujui",
      data: "APPROVED",
      color: colors.secondary.orchid,
    },
    {
      id: 2,
      name: "Menunggu",
      data: "REQUESTED",
      color: colors.warning.sunflower,
    },
    {
      id: 3,
      name: "Ditolak",
      data: "REJECTED",
      color: colors.error.rose,
    },
    {
      id: 4,
      name: "Menunggu",
      data: "DRAFT",
      color: colors.warning.sunflower,
    },
    {
      id: 2,
      name: "Menunggu",
      data: "PENDING",
      color: colors.warning.sunflower,
    },
  ],
  disbursementStatus: [
    {
      id: 1,
      name: "Menunggu Dicairkan",
      data: "PENDING",
      color: colors.warning.sunflower,
    },
    {
      id: 2,
      name: "Sukses",
      data: "COMPLETED",
      color: colors.success.cactus,
    },
    {
      id: 3,
      name: "Ditolak",
      data: "REJECTED",
      color: colors.error.rose,
    },
    {
      id: 2,
      name: "Sukses",
      data: "DISBURSED",
      color: colors.success.cactus,
    },
  ],
};

export const ACTION_OPTIONS_CREATE_UPDATE = [
  { id: 1, name: "Penambahan Data", data: "create_employee" },
  { id: 2, name: "Perubahan Data Karyawan", data: "update_employee" },
];

export const ACTION_OPTIONS_STATUS = [{ id: 1, name: "Perubahan Data Status", data: "update_employee_status" }];

export const TYPE_OPTIONS = [
  { id: 1, name: "Kerah Biru", data: "BLUE" },
  { id: 2, name: "Kerah Putih", data: "WHITE" },
];

export const BANK_OPTIONS = [
  { id: 1, name: "Bank BCA" },
  { id: 2, name: "Bank HSBC" },
];

export const TRANSACTION_STATUS_TYPES = {
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  // SUCCESS: "SUCCESS", TODO: commented as PRODUCT has requested that these Txn status types will not be used
  // CANCELLED: "CANCELLED",
  // NOT_STARTED: "NOT_STARTED",
};

const TRANSACTION_NAMES = [
  "eMoney",
  "Konsultasi Dokter",
  "Listrik postpaid",
  "Paket Data",
  "Pascabayar",
  "PDAM",
  "Pembelian Emas",
  "Penarikan Gaji",
  "Pulsa",
  "Token PLN",
  "Transfer",
  "Voucher",
  "Zakat Fitrah",
  "e-Kasbon",
];

const TRANSACTION_IDS = [
  "BP-15",
  "BP-18",
  "BP-17",
  "BP-07",
  "BP-08",
  "BP-06",
  "GD-01",
  "WD-01",
  "BP-10",
  "BP-05",
  "WD-02",
  "BP-13",
  "BP-29",
  "LP-01",
];

export const TRANSACTION_REASON_TYPES = TRANSACTION_NAMES.map((name, index) => ({
  name,
  id: TRANSACTION_IDS[index],
}));

export const EMPLOYEE_UPDATE_REQUEST_STATUS = {
  PENDING: "pending",
  COMPLETED: "completed",
  REJECTED: "rejected",
  FAILED: "failed",
  PROCESSING: "processing",
};

export const BULK_UPDATE_TEMPLATE_LINKS = {
  CREATE: `${config.TEMPLATE_URL}Template_Bulk_Upload_Banyak_Karyawan_v6.xlsx`,
  UPDATE: `${config.TEMPLATE_URL}Template_Ubah_Data_Beberapa_Karyawan_v4.xlsx`,
  UPDATE_STATUS: `${config.TEMPLATE_URL}Template_Ubah_Beberapa_Status_Karyawan_v4.xlsx`,
};

export const BULK_TYPES = {
  CREATE: "bulk_employee_create",
  UPDATE: "bulk_employee_update",
  UPDATE_STATUS: "bulk_employee_update_status",
  IOB_CREATE: "bulk_onboard_employee_create",
};

export const OTP_TYPES = {
  WHATSAPP: "WHATSAPP",
  SMS: "SMS",
};

export const SD_TYPES = {
  SD_SCHEDULING: "Penjadwalan Salary Disbursement",
};

export const SD_SCHEDULING_TEMPLATE = `${config.TEMPLATE_URL}Template+Penjadwalan+Salary+Disbursement.xlsx`;

export const SD_SCHEDULING = "sd_scheduling";

export const MANDATORY_COLUMN_NAMES = [
  "Nomor Handphone (dimulai dengan 62)",
  "Nomor Rekening",
  "Total Pengajuan (contoh: 1000000)",
  "Berita Transfer",
  "Email Perusahaan (untuk menerima bukti transfer)",
];

export const dateFormat = "DD/MM/YYYY";
export const homeSummaryEmployee = "home.summary.employee";
export const basicFieldMissing = "basicValidation.missingFields";

export const MODAL_ALERTS = {
  WS_MODAL: {
    titleText: "modalAlerts.ws.title",
    imageSource: ArtAccountSuspended,
    contents: [{ text: "modalAlerts.ws.content", isBold: false }],
    buttonText: "modalAlerts.ws.btnText",
    amplitudeKey: "ws",
  },
  DORMANT_USER: {
    titleText: "modalAlerts.dormant.title",
    imageSource: ArtEmptySeat,
    contents: [
      { text: "modalAlerts.dormant.content1", isBold: false },
      { text: "modalAlerts.dormant.content2", isBold: true },
      { text: "modalAlerts.dormant.content3", isBold: false },
    ],
    buttonText: "modalAlerts.dormant.btnText",
    amplitudeKey: "dormant",
  },
  INITIAL_ONBOARDING: {
    titleText: "modalAlerts.iob.title",
    imageSource: ArtFilling,
    contents: [{ text: "modalAlerts.iob.content", isBold: false }],
    buttonText: "modalAlerts.iob.btnText",
    amplitudeKey: "initialOnboarding",
  },
  IOB_CONGRATULATIONS: {
    titleText: "modalAlerts.iob_congo.title",
    imageSource: ArtCongratulations,
    contents: [{ text: "modalAlerts.iob_congo.content", isBold: false }],
    buttonText: "modalAlerts.iob_congo.btnText",
    amplitudeKey: "iob_congratulations",
  },
  ACCESS_DENIED: {
    titleText: "modalAlerts.noAccess.title",
    contents: [{ text: "modalAlerts.noAccess.content", isBold: false }],
    buttonText: "modalAlerts.noAccess.btnText",
    imageSource: ArtNoAccess,
  },
};

export const EMPLOYER_STATUS_TYPE = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  WITHDRAW_SUSPENDED: "withdraw_suspended",
  WITHDRAW_ACCURAL_SUSPENDED: "withdraw_accrual_suspended",
};

export const STATUS_FILTER = {
  active: {
    code: EMPLOYER_STATUS_TYPE.ACTIVE,
    name: "home.list.rows.active",
  },
  withdraw_suspended: {
    code: EMPLOYER_STATUS_TYPE.WITHDRAW_SUSPENDED,
    name: homeListInactiveRows,
  },
  withdraw_accrual_suspended: {
    code: EMPLOYER_STATUS_TYPE.WITHDRAW_ACCURAL_SUSPENDED,
    name: homeListInactiveRows,
  },
  inactive: {
    code: EMPLOYER_STATUS_TYPE.INACTIVE,
    name: homeListInactiveRows,
  },
};

export const EMPLOYEE_REQUEST_TYPE = {
  CREATE: "create_employee",
  UPDATE: "update_employee",
  UPDATE_STATUS: "update_employee_status",
};

export const ROLE_EMPLOYEE = "EMPLOYEE";
export const ROLE_GROUP_PAYDATE = "GROUP-PAYDATE";
export const ROLE_SD = "SD";
export const ROLE_TRANSACTION = "TRANSACTION";
export const ROLE_TEAM_ACCESS = "TEAM-ACCESS";
export const ROLE_INVOICES = "INVOICE";
export const ROLE_LIMIT = "LIMIT";

export const ACCESS_ROLES = [
  { id: 0, role: ROLE_EMPLOYEE, textColor: colors.success.cactus, bgColor: colors.success.softCactus },
  { id: 10, role: ROLE_GROUP_PAYDATE, textColor: colors.warning.darkSunflower, bgColor: colors.warning.softSunflower },
  { id: 30, role: ROLE_SD, textColor: colors.neutral.charcoal, bgColor: colors.neutral.mist },
  { id: 20, role: ROLE_TRANSACTION, textColor: colors.secondary.orchid, bgColor: colors.secondary.softOrchid },
  { id: 40, role: ROLE_TEAM_ACCESS, textColor: colors.neutral.cotton, bgColor: colors.neutral.darkMist },
  { id: 50, role: ROLE_INVOICES, textColor: colors.neutral.cotton, bgColor: colors.neutral.darkMist },
];
export const INVOICES_STATUS_TYPS = {
  PAID: "PAID",
  DUE: "DUE",
  OVERDUE: "OVERDUE",
};

export const INVOICES_STATUS_STYLES = {
  [INVOICES_STATUS_TYPS.PAID]: {
    textColor: colors.success.cactus,
    bgColor: colors.success.softCactus,
    text: "Paid",
  },
  [INVOICES_STATUS_TYPS.DUE]: {
    textColor: colors.warning.sunflower,
    bgColor: colors.warning.softSunflower,
    text: "Due",
  },
  [INVOICES_STATUS_TYPS.OVERDUE]: { textColor: colors.error.rose, bgColor: colors.error.softRose, text: "Overdue" },
};
export const INVOICE_DISPUTE_SUPPORT = "finance@gajigesa.com,\nemployer-partner@gajigesa.com";

const mandatoryDocuments = (t) => [
  { name: t("limitRequest.mandatoryDocList.L3MBS"), key: "L3MBS" },
  { name: t("limitRequest.mandatoryDocList.CAWC"), key: "CAWC" },
  { name: t("limitRequest.mandatoryDocList.LD"), key: "LD" },
  { name: t("limitRequest.mandatoryDocList.SKM"), key: "SKM" },
  { name: t("limitRequest.mandatoryDocList.IDCM"), key: "IDCM" },
];

export const additionalDocuments = (t) => [
  { name: t("limitRequest.additionalDocList.FSLY"), key: "FSLY" },
  { name: t("limitRequest.additionalDocList.FSCY"), key: "FSCY" },
  { name: t("limitRequest.additionalDocList.CP"), key: "CP" },
  { name: t("limitRequest.additionalDocList.SIHRA"), key: "SIHRA" },
  { name: t("limitRequest.additionalDocList.AED"), key: "AED" },
  { name: t("limitRequest.additionalDocList.BPJS"), key: "BPJS" },
];

const ewaLimitRules = (t) => ({
  mandatoryDocuments: mandatoryDocuments(t),
  additionalDocuments: additionalDocuments(t),
  isAdditionalDocumentRequired: false,
});

const sdLimitRules = (t) => ({
  mandatoryDocuments: mandatoryDocuments(t),
  additionalDocuments: additionalDocuments(t),
  isAdditionalDocumentRequired: true,
});

export const documents = (t) => ({
  0: ewaLimitRules(t),
  1: sdLimitRules(t),
  2: sdLimitRules(t),
});

/*
Sample Employee record

{
    "employeeId": 2,
    "userId": 6,
    "employeeCode": "2",
    "employeeName": "pankaj",
    "employeeGroup": null,
    "employeeStatus": "active",
    "withdrawalLimit": 0,
    "payDate": "28",
    "employeeAccounts": [
        "Akun Gaji",
        "POINTS Account"
    ]
}

Sample Progress Record
{
    "id": 192,
    "employerId": 577,
    "employeeId": null,
    "userId": null,
    "requestType": "create_employee",
    "requestedBy": 175971,
    "status": "pending",
    "statusReason": null,
    "statusUpdatedAt": "2023-09-15T16:59:20.366Z",
    "statusUpdatedBy": 175971,
    "firstName": "Desy",
    "lastName": "",
    "birthDate": "1980-01-11",
    "mobileNumber": "081755832028",
    "gender": "FEMALE",
    "ktpNumber": "9583920958673829",
    "employeeCode": "SINGLE555",
    "joinedAt": "2023-09-13",
    "collarType": "WHITE",
    "groupName": "Product",
    "departmentName": null,
    "designationName": null,
    "salaryType": "FIXED_MONTH",
    "payDate": "-1",
    "salaryAmount": 3000000,
    "bankName": "Bank BCA",
    "bankAccountNo": "08559448291",
    "beneficiaryName": "Desy",
    "employeeStatus": null,
    "fileName": null,
    "fromData": null,
    "toData": null,
    "createdAt": "2023-09-15T16:59:20.366Z",
    "updatedAt": "2023-09-15T16:59:20.366Z",
    "employee": null
},
*/

export const pageSize = 10;

export const tableCellWidth = ["120px", "130px", "130px", "130px", "185px"];

export const LIMIT_REQUEST_TYPES = [
  {
    id: 0,
    name: "Limit EWA",
    amplitudeEvent: "ewa_limit_request_creation_clicked",
  },
  {
    id: 1,
    name: "Limit SD",
    amplitudeEvent: "sd_limit_request_creation_clicked",
  },
  {
    id: 2,
    name: "Limit EWA & SD",
    amplitudeEvent: "ewa_sd_limit_request_creation_clicked",
  },
];

export const LIMIT_REQUEST_TYPE_OBJ = {
  0: "EWA",
  1: "SD",
  2: "EWA & SD",
};

export const LIMIT_REQUEST_APPLICATION_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  NEEDS_CHECK: "NEEDS CHECKING",
  ACTUAL_STATUS: "ACTUAL STATUS",
  ACTUAL_APPROVED_LIMIT: "ACTUAL APPROVED LIMIT",
};

export const LIMIT_FILE_STATUS = {
  ...LIMIT_REQUEST_APPLICATION_STATUS,
  AWAITING_UPLOAD: "AWAITING UPLOAD",
  UPLOADED: "UPLOADED",
};

export const LIMIT_REQUEST_STYLES = [
  {
    id: 1,
    status: LIMIT_REQUEST_APPLICATION_STATUS.PENDING,
    textColor: colors.secondary.orchid,
    bgColor: colors.secondary.softOrchid,
    text: "limitRequests.status.pending",
  },
  {
    id: 2,
    status: LIMIT_REQUEST_APPLICATION_STATUS.APPROVED,
    textColor: colors.success.cactus,
    bgColor: colors.success.softCactus,
    text: "limitRequests.status.approved",
  },
  {
    id: 3,
    status: LIMIT_REQUEST_APPLICATION_STATUS.NEEDS_CHECK,
    textColor: colors.error.rose,
    bgColor: colors.error.softRose,
    text: "limitRequests.status.needsCheck",
  },
  {
    id: 4,
    status: LIMIT_REQUEST_APPLICATION_STATUS.REJECTED,
    textColor: colors.error.rose,
    bgColor: colors.error.softRose,
    text: "limitRequests.status.rejected",
  },
];

export const LIMIT_FILE_STYLES = [
  {
    id: 1,
    status: LIMIT_FILE_STATUS.PENDING,
    textColor: colors.secondary.orchid,
    bgColor: colors.secondary.softOrchid,
    text: "limitRequests.status.pending",
  },
  {
    id: 2,
    status: LIMIT_FILE_STATUS.APPROVED,
    textColor: colors.success.cactus,
    bgColor: colors.success.softCactus,
    text: "limitRequests.status.approved",
  },
  {
    id: 3,
    status: LIMIT_FILE_STATUS.AWAITING_UPLOAD,
    textColor: colors.warning.darkSunflower,
    bgColor: colors.warning.softSunflower,
    text: "limitRequests.status.awaitingUpload",
  },
  {
    id: 4,
    status: LIMIT_FILE_STATUS.REJECTED,
    textColor: colors.error.rose,
    bgColor: colors.error.softRose,
    text: "limitRequests.status.rejected",
  },
  {
    id: 5,
    status: LIMIT_FILE_STATUS.UPLOADED,
    textColor: colors.secondary.daybreak,
    bgColor: colors.secondary.daybreakLight,
    text: "limitRequests.status.uploaded",
  },
  {
    id: 6,
    status: LIMIT_REQUEST_APPLICATION_STATUS.NEEDS_CHECK,
    textColor: colors.error.rose,
    bgColor: colors.error.softRose,
    text: "limitRequests.status.needsCheck",
  },
];
