import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Flex, HStack, Input, Text, useToast, View, VStack } from "native-base";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomTabPanel from "atoms/CustomTabPanel";
import { documents, LIMIT_FILE_STATUS, LIMIT_REQUEST_APPLICATION_STATUS } from "global/constants";
import TableMandatoryDocuments from "molecules/TableMandatoryDocuments";
import TableLimitDecision from "molecules/TableLimitDecision";
import MandatoryTabActions from "molecules/MandatoryTabActions";
import AdditionalTabActions from "molecules/AdditionalTabActions";
import DecisionTabActions from "molecules/DecisionTabActions";
import ContentContainer from "atoms/ContentContainer";
import colors from "theme/colors";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { submitLimitRequestAPI } from "api/apiCall";
import { LIMIT_REQUEST } from "global/paths";
import { fetchLimitRequestDetails } from "api/useQueryCalls";
import Breadcrumbs from "atoms/Breadcrumbs";
import config from "config";
import tenantConfig from "tenantConfig";
import { showErrorToast } from "utils/utilities";
import { getStore } from "store/storeUtils";
import EWALimitCurrentAndRequired from "atoms/EWALimitCurrentAndRequired";
import SDLimitCurrentAndRequired from "atoms/SDLimitCurrentAndRequired";

function shouldShowAsteriksForAdditionalDocuments(limitsData, requestType) {
  return limitsData?.isAdditionalRequired || requestType !== "0" ? <ShowAsteriks /> : null;
}

function shouldShowLimitInputs(requestType, limit, currencySymbol, requestLimits) {
  const calculateResult = (requestLimit, limitValue) => {
    return (requestLimit ?? 0) === 0 ? 0 : (requestLimit ?? 0) - (limitValue ?? 0);
  };

  if (requestType === "0") {
    return (
      <EWALimitCurrentAndRequired
        limit={limit?.ewa ?? 0}
        currencySymbol={currencySymbol}
        requestLimits={calculateResult(requestLimits?.ewa, limit?.ewa)}
      />
    );
  }
  if (requestType === "1") {
    return (
      <SDLimitCurrentAndRequired
        limit={limit?.sd ?? 0}
        currencySymbol={currencySymbol}
        requestLimits={calculateResult(requestLimits?.sd, limit?.sd)}
      />
    );
  }
  if (requestType === "2") {
    return (
      <>
        <View marginBottom="6px">
          <EWALimitCurrentAndRequired
            limit={limit?.ewa ?? 0}
            currencySymbol={currencySymbol}
            requestLimits={calculateResult(requestLimits?.ewa, limit?.ewa)}
          />
        </View>
        <View>
          <SDLimitCurrentAndRequired
            limit={limit?.sd ?? 0}
            currencySymbol={currencySymbol}
            requestLimits={calculateResult(requestLimits?.sd, limit?.sd)}
          />
        </View>
      </>
    );
  }
  return null;
}

function showDocumentForType(type, t) {
  return documents(t)[type];
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function getCanProceedForAdditonalDocuments(values, requestType, isAdditionalRequired) {
  return (
    Array.isArray(values) &&
    values.every((doc) => {
      const isAwaitingOrNeedsCheck =
        doc.status === LIMIT_FILE_STATUS.AWAITING_UPLOAD || doc.status === LIMIT_FILE_STATUS.NEEDS_CHECK;

      if (requestType === "0" && isAdditionalRequired) {
        return !isAwaitingOrNeedsCheck;
      }

      if (requestType === "1" || requestType === "2") {
        return !isAwaitingOrNeedsCheck;
      }

      return requestType === "0" && !isAdditionalRequired;
    })
  );
}

// 1 trillion = 1_000_000_000_000 (12 zeros)
const MAX_LIMIT = 1000000000000;

const numericStringSchema = (t) =>
  Yup.string()
    // Remove thousand separators (dots) before validation
    .transform((value) => (value ? value.replace(/\./g, "") : value))
    .test("is-numeric", t("limitRequest.errorMessages.mustBeNumber"), (val) => {
      if (!val) return false;
      return /^\d+$/.test(val); // only digits
    })
    .test("no-leading-zeros", t("limitRequest.errorMessages.noLeadingZeros"), (val) => {
      if (!val) return false;
      // If length > 1 and starts with '0', it's invalid
      return !(val.length > 1 && val[0] === "0");
    })
    .test("min", t("limitRequest.errorMessages.minimumVal"), (val) => parseInt(val, 10) >= 1)
    .test("max", t("limitRequest.errorMessages.maximumVal"), (val) => parseInt(val, 10) <= MAX_LIMIT);

const getValidationSchema = (requestType, isAdditionalRequired, t) =>
  Yup.object().shape({
    ewaLimit: Yup.string()
      .nullable()
      .when([], {
        is: () => requestType === "0" || requestType === "2",
        then: (schema) => schema.required(t("limitRequest.errorMessages.ewaRequired")).concat(numericStringSchema(t)),
        otherwise: (schema) => schema.nullable(),
      }),
    totalEWALimit: 0,
    sdLimit: Yup.string()
      .nullable()
      .when([], {
        is: () => requestType === "1" || requestType === "2",
        then: (schema) => schema.required(t("limitRequest.errorMessages.sdRequired")).concat(numericStringSchema(t)),
        otherwise: (schema) => schema.nullable(),
      }),

    totalSDLimit: 0,
    mandatoryDocuments: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(),
          key: Yup.string().required(),
          fileName: Yup.string(),
          status: Yup.string()
            .oneOf([
              LIMIT_FILE_STATUS.AWAITING_UPLOAD,
              LIMIT_FILE_STATUS.PENDING,
              LIMIT_FILE_STATUS.APPROVED,
              LIMIT_FILE_STATUS.UPLOADED,
            ])
            .required(),
        }),
      )
      .test("all-uploaded-mandatory", "All mandatory documents must be uploaded", (value) => {
        if (!value) return true; // If no documents, no check
        // For requestType = 0, 1, or 2, mandatory docs must be uploaded
        if (requestType === "0" || requestType === "1" || requestType === "2") {
          return value.every(
            (doc) =>
              doc.status === LIMIT_FILE_STATUS.UPLOADED ||
              doc.status === LIMIT_FILE_STATUS.PENDING ||
              doc.status === LIMIT_FILE_STATUS.APPROVED,
          );
        }
        return true;
      }),
    additionalDocuments: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(),
          key: Yup.string().required(),
          fileName: Yup.string(),
          status: Yup.string()
            .oneOf([
              LIMIT_FILE_STATUS.AWAITING_UPLOAD,
              LIMIT_FILE_STATUS.APPROVED,
              LIMIT_FILE_STATUS.PENDING,
              LIMIT_FILE_STATUS.UPLOADED,
            ])
            .required(),
        }),
      )
      .test("all-uploaded-additional", "All additional documents must be uploaded", (value) => {
        return getCanProceedForAdditonalDocuments(value, requestType, isAdditionalRequired);
      }),
    reason: Yup.string()
      .required(t("limitRequest.errorMessages.reasonRequired"))
      .min(5, t("limitRequest.errorMessages.reasonMinCharacters")),
  });

function ShowAsteriks() {
  return (
    <span
      style={{
        color: "red",
        fontSize: "1.2rem",
      }}
    >
      *
    </span>
  );
}

export function getDoc(dockey, viewDocKey) {
  return dockey === viewDocKey;
}

export function onLoadDocumentsSettings(values, documents, setFieldValue, name) {
  const docs = values[name] || [];
  if (documents?.length > 0 && docs?.length > 0) {
    const updatedDocs = docs.map((d) => {
      const foundIdx = documents.findIndex((o) => getDoc(d?.key, o?.docType));
      if (foundIdx > -1) {
        return {
          ...d,
          ...documents[foundIdx],
        };
      }
      return d;
    });
    setFieldValue(name, updatedDocs);
  }
}

const LimitRequest = () => {
  const location = useLocation();
  const { state } = location;
  const { currencySymbol } = tenantConfig[config.COUNTRY_CODE];
  const [limitsData, setLimitsData] = useState(state?.currentLimits || { ewa: 0, sd: 0 });
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { resetLoginData } = getStore();
  const {
    isSuccess,
    data,
    mutate: callSubmitLimitRequest,
  } = useMutation({
    mutationFn: (payload) => submitLimitRequestAPI(payload),
    onError: (error) => {
      showErrorToast({ error, toast, navigate, resetLoginData });
    },
  });

  const [searchParams] = useSearchParams();
  const requestType = searchParams.get("requestType"); // "0", "1", or "2"
  const type = searchParams.get("type");
  const requestId = searchParams.get("reqId");

  const queryClient = useQueryClient();
  const {
    data: limitRequestDetailsData,
    isSuccess: limitRequestDetailsSuccess,
    refetch: fetchLimitRequestDetailsApiCall,
    isError: isLimitRequetError,
    error: limitRequestDetailsError,
  } = fetchLimitRequestDetails(requestId);

  const [tabValue, setTabValue] = useState(type === "view" ? 2 : 0);
  const handleTabChange = (e, newValue) => setTabValue(newValue);

  const validationSchema = getValidationSchema(requestType, limitRequestDetailsData?.isAdditionalRequired, t);
  const documentsForType = showDocumentForType(requestType, t);

  useEffect(() => {
    if (limitRequestDetailsData?.isAdditionalRequired) setTabValue(1);
  }, [limitRequestDetailsData]);

  useEffect(() => {
    if (isSuccess && data) navigate(LIMIT_REQUEST);
  }, [data, isSuccess]);

  useEffect(() => {
    if (isLimitRequetError && limitRequestDetailsError) {
      showErrorToast({ error: limitRequestDetailsError, toast, navigate, resetLoginData });
    }
  }, [isLimitRequetError, limitRequestDetailsError]);

  useEffect(() => {
    if (type === "view" && requestId) fetchLimitRequestDetailsApiCall();
    return () => {
      queryClient.removeQueries("fetchLimitRequestDetails", requestId);
    };
  }, []);

  useEffect(() => {
    if (limitRequestDetailsSuccess && limitRequestDetailsData) {
      setLimitsData(limitRequestDetailsData?.currentLimits);
    }
  }, [limitRequestDetailsSuccess, limitRequestDetailsData]);

  const initialValues = {
    ewaLimit: null,
    sdLimit: null,
    totalEWALimit: null,
    totalSDLimit: null,
    totalEWApprovedLimit: limitRequestDetailsData?.approvedLimits?.ewa,
    totalSDApprovedLimit: limitRequestDetailsData?.approvedLimits?.sd,
    mandatoryDocuments: documentsForType?.mandatoryDocuments
      ? documentsForType.mandatoryDocuments.map((doc) => ({
          ...doc,
          fileName: "",
          status: LIMIT_FILE_STATUS.AWAITING_UPLOAD,
        }))
      : [],
    additionalDocuments: documentsForType?.additionalDocuments
      ? documentsForType.additionalDocuments.map((doc) => ({
          ...doc,
          fileName: "",
          status: LIMIT_FILE_STATUS.AWAITING_UPLOAD,
        }))
      : [],
    reason: type === "view" ? limitRequestDetailsData?.applyReason : "",
  };

  return (
    <ContentContainer title={t("limitRequests.newApplication")} flex={[1, 1, 1, 0.85, 0.85]}>
      <Breadcrumbs
        currentPage="sideBar.limitRequest"
        parentScreen={[{ name: "sideBar.limitRequest", link: LIMIT_REQUEST }]}
      />
      <Flex
        bg={colors.neutral.cotton}
        my="25px"
        mb="20px"
        p="15px"
        py="10px"
        flex={1}
        borderRadius={12}
        maxWidth="1600px"
        overflow="hidden"
      >
        <HStack
          space={3}
          style={{
            height: "100%",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <VStack style={{ flexWrap: "wrap", flex: 1 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur
              validateOnChange
              enableReinitialize
              onSubmit={(values) => {
                // Handle the final submission logic here
                const submitRequest = {
                  requestId,
                  requestType,
                  ewaLimit: values.totalEWALimit,
                  sdLimit: values.totalSDLimit,
                  reason: values.reason,
                };
                callSubmitLimitRequest(submitRequest);
              }}
            >
              {({ values, setFieldValue, errors, handleSubmit, handleBlur, handleChange }) => {
                useEffect(() => {
                  if (limitRequestDetailsData) {
                    onLoadDocumentsSettings(
                      values,
                      limitRequestDetailsData?.mandatoryDocs,
                      setFieldValue,
                      "mandatoryDocuments",
                    );
                    onLoadDocumentsSettings(
                      values,
                      limitRequestDetailsData?.additionalDocs,
                      setFieldValue,
                      "additionalDocuments",
                    );
                  }
                }, [limitRequestDetailsData]);

                const includesNeedsCheck = ![
                  limitRequestDetailsData?.decision?.mandatoryDocsResult,
                  limitRequestDetailsData?.decision?.additionalDocResult,
                  limitRequestDetailsData?.decision?.result,
                ].includes(LIMIT_REQUEST_APPLICATION_STATUS.NEEDS_CHECK);

                const hideSubmitBtn = type === "view" && includesNeedsCheck;

                return (
                  <Form>
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <View marginLeft="5px" marginTop="15px" alignSelf="center" marginBottom="24px">
                          {shouldShowLimitInputs(
                            requestType,
                            limitsData,
                            currencySymbol,
                            limitRequestDetailsData?.limits,
                          )}
                          <View marginTop="15px">
                            <Text flex={1} variant="xs">
                              {t("limitRequest.reasonForIncrease")}
                            </Text>
                            <Input
                              name="reason"
                              marginTop="5px"
                              value={values.reason}
                              placeholder={t("limitRequest.inputReasonForIncrease")}
                              isDisabled={type === "view"}
                              onChangeText={handleChange("reason")}
                              onBlur={handleBlur("reason")}
                            />
                            {errors.reason && <Text color="red.500">{errors.reason}</Text>}
                          </View>
                        </View>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Limit tab">
                          <Tab
                            style={{ alignContent: "center" }}
                            label={
                              <span>
                                {t("limitRequest.mandatoryDocuments")}
                                <ShowAsteriks />
                              </span>
                            }
                            {...a11yProps(0)}
                          />
                          <Tab
                            style={{ alignContent: "center" }}
                            label={
                              <span>
                                {t("limitRequest.additionalDocuments")}
                                {shouldShowAsteriksForAdditionalDocuments(limitRequestDetailsData, requestType)}
                              </span>
                            }
                            {...a11yProps(1)}
                          />
                          <Tab label={t("limitRequest.decision")} {...a11yProps(2)} />
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={tabValue} index={0}>
                        <TableMandatoryDocuments
                          name="mandatoryDocuments"
                          reqId={requestId}
                          documents={limitRequestDetailsData?.mandatoryDocs}
                          isRejected={
                            limitRequestDetailsData?.decision?.mandatoryDocsResult ===
                              LIMIT_REQUEST_APPLICATION_STATUS.REJECTED ||
                            limitRequestDetailsData?.decision?.result === LIMIT_REQUEST_APPLICATION_STATUS.REJECTED
                          }
                          isApproved={
                            limitRequestDetailsData?.decision?.mandatoryDocsResult ===
                              LIMIT_REQUEST_APPLICATION_STATUS.APPROVED ||
                            limitRequestDetailsData?.decision?.result === LIMIT_REQUEST_APPLICATION_STATUS.APPROVED
                          }
                        />
                        <MandatoryTabActions
                          requestType={requestType}
                          setValue={setTabValue}
                          isCreate={type === "create"}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabValue} index={1}>
                        <TableMandatoryDocuments
                          name="additionalDocuments"
                          reqId={requestId}
                          documents={limitRequestDetailsData?.additionalDocs}
                          isAdditionalRequired={limitRequestDetailsData?.isAdditionalRequired}
                          isRejected={
                            limitRequestDetailsData?.decision?.additionalDocResult ===
                              LIMIT_REQUEST_APPLICATION_STATUS.REJECTED ||
                            limitRequestDetailsData?.decision?.result === LIMIT_REQUEST_APPLICATION_STATUS.REJECTED
                          }
                          isApproved={
                            limitRequestDetailsData?.decision?.additionalDocResult ===
                              LIMIT_REQUEST_APPLICATION_STATUS.APPROVED ||
                            limitRequestDetailsData?.decision?.result === LIMIT_REQUEST_APPLICATION_STATUS.APPROVED
                          }
                        />
                        <AdditionalTabActions
                          setValue={setTabValue}
                          requestType={requestType}
                          isAdditionalRequired={limitRequestDetailsData?.isAdditionalRequired}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={tabValue} index={2}>
                        <Text>{t("limitRequest.decisionHeader")}</Text>
                        <TableLimitDecision
                          requestType={requestType}
                          reqId={requestId}
                          mandatoryDocsReviewStatus={limitRequestDetailsData?.decision?.mandatoryDocsResult}
                          additionalDocReviewStatus={limitRequestDetailsData?.decision?.additionalDocResult}
                          finalDecisionStatus={limitRequestDetailsData?.decision?.result}
                          updatedAt={limitRequestDetailsData?.updatedAt}
                          createdAt={limitRequestDetailsData?.createdAt}
                        />
                        <DecisionTabActions
                          requestType={requestType}
                          isAdditionalRequired={limitRequestDetailsData?.isAdditionalRequired}
                          values={values}
                          errors={errors}
                          handleSubmit={handleSubmit}
                          hide={hideSubmitBtn}
                        />
                      </CustomTabPanel>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </VStack>
        </HStack>
      </Flex>
    </ContentContainer>
  );
};

export default LimitRequest;
