import { Input } from "native-base";
import { numberWithDots } from "utils/helpers";
import PropTypes from "prop-types";

function InputCurrency(props) {
  const { testID, disabled, input, onValueChange, onValueFocus, onValueBlur, customStyles, withDots } = props;
  return (
    <Input
      variant="home"
      isDisabled={disabled}
      testID={testID}
      value={withDots ? numberWithDots(input) : input}
      onChange={onValueChange}
      onFocus={onValueFocus}
      onBlur={onValueBlur}
      style={customStyles}
      textAlign="right"
    />
  );
}

InputCurrency.propTypes = {
  testID: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.string,
  onValueChange: PropTypes.func,
  onValueFocus: PropTypes.func,
  onValueBlur: PropTypes.func,
  withDots: PropTypes.bool,
};

InputCurrency.defaultProps = {
  testID: "",
  disabled: false,
  input: "",
  onValueChange: () => {},
  onValueFocus: () => {},
  onValueBlur: () => {},
  withDots: true,
};

export default InputCurrency;
